import { Mutex } from 'async-mutex';
import { getCookie, setCookie, getCurrentUserId } from '../utils';
import {
  optionsGetSimple, CustomRequest, URL,
} from './requst_templates';
import {getAuthUserPing } from './pages/users'

let clientLock = new Mutex();

const checkResponse = (res: Response) => (res.ok ? res.json() : Promise.reject(res));

const refreshToken = async () => fetch(`${URL}/v1/refresh`, {
  method: 'POST',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
  body: JSON.stringify({
    accessToken: getCookie('token'),
    refreshToken: getCookie('refreshToken')
  }),
}).then(checkResponse);

export const fetchWithRefresh = async (url: string, options: CustomRequest) => {
  const origToken = getCookie('token');
  const res = await fetch(url, options);

  if (res.status === 403 || res.status === 401) {
    let release = await clientLock.acquire();
    try {
      if (origToken !== getCookie('token')) {
        return fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${getCookie('token')}`,
          },
        });
      } else {
        const refreshData = await refreshToken();
        setCookie('token', refreshData.accessToken);
        setCookie('refreshToken',refreshData.refreshToken);
        if (options.headers) {
          options.headers.Authorization = refreshData.accessToken;
        }
        return fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${refreshData.accessToken}`,
          },
        });
      }
    } finally {
      release();
    }
  }
  return res;
};

export const loginRequest = async (form: object) => fetch(`${URL}/v1/auth`, {
  method: 'POST',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
  body: JSON.stringify(form),
});

export const usersInfoRequest = async () => fetchWithRefresh(`${URL}/v1/users`, optionsGetSimple());

export const authUserPing = async () => getAuthUserPing(getCurrentUserId()||'');
