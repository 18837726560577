import React, {FC} from 'react';
import {uuid} from '../../utils';

export type CliLineOutputProps = {


    line?: string;

};

export const CliLineOutput: FC<CliLineOutputProps> = ({

                                                          line
                                                      }) => {


    return (
        <span className={'body_1_long'}>
             {line}<br/>
        </span>
    )
}
