import React, {FC} from "react";
import {Outlet} from "react-router";

export const Tools: FC = () => {

    return (
        <div>
            <Outlet/>
        </div>
    );
}
