import React, {FC} from 'react';
import styles from './RowDeleteComponent.module.scss';

import {DeleteFilled} from '@ant-design/icons';
import {Tooltip} from 'antd';

export type RowDeleteComponentProps = {
    title?: string;
    className?: string;
    onClickHandler?: () => void;
    hidden?: boolean;
};

export const RowDeleteComponent: FC<RowDeleteComponentProps> = ({
                                                                    title,
                                                                    hidden,
                                                                    className,
                                                                    onClickHandler
                                                                }) => {

    return (

        <React.Fragment>
            {hidden ? <></> :

                <div id="delete_tooltip">
                    <Tooltip title={title} getPopupContainer={() => document.getElementById('delete_tooltip')!}>
                        <DeleteFilled className={styles.item_icon}
                                      hidden={hidden}
                                      onClick={onClickHandler}/>
                    </Tooltip>
                </div>

            }
        </React.Fragment>
    );

}
