/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import {Form} from '../../components/Form';
import {SignBackground} from '../../components/SignBackground';
import {loginRequest} from '../../services/auth';
import {i18n, setCookie} from '../../utils';
import {URL} from '../../services/requst_templates';
import {ReactComponent as Logo} from '../../assets/icons/damask_icon_login.svg';


import {EyeFilled, EyeInvisibleFilled} from '@ant-design/icons';
import {Alert, Checkbox, ConfigProvider, Input} from 'antd';


import {changeLoginAction, changeTokenAction, changeValidateAction,} from '../../redux/reducers/auth';
import {ChangeLoginAction, ChangeTokenAction, ChangeValidateAction} from '../../types/redux/auth';
import styles from './SignIn.module.scss';
import {AuthError} from '../../types/AuthError';

export function SignIn() {
    const navigate = useNavigate();

    const [loadings, setLoadings] = useState<boolean>(false);


    const submitHandler = (
        event: React.FormEvent<HTMLFormElement>,
        dispatch: Dispatch,
        setErrorText: React.Dispatch<React.SetStateAction<string>>,
    ): void => {
        setLoadings(true);

        const email = document.getElementById('email') as HTMLFormElement;
        const password = document.getElementById('password') as HTMLFormElement;
        const result: { [key: string]: string } = {};
        result[email.name] = email.value;
        result[password.name] = password.value;
        setLoadings(false);

        if (result.email && result.password) {
            loginRequest({
                username: result.email,
                password: result.password,
                language: 'ru'
            }).then(async (resp) => {
                if (!resp.ok) {
                    let authResp = await resp.json() as AuthError;
                    let msg = getAuthErrorMessage(authResp);
                    throw new Error(msg);
                }
                return resp;
            })
                .then((response) => response.json())
                .then((json) => {
                    const token: string = json.accessToken as string;
                    const refreshToken: string = json.refreshToken as string;
                    const login: string = result.email;
                    if (token) {
                        setCookie('token', token);
                        setCookie('login', login);
                        setCookie('refreshToken', refreshToken);
                        dispatch(changeTokenAction(token) as ChangeTokenAction);
                        dispatch(changeLoginAction(login) as ChangeLoginAction);
                        dispatch(changeValidateAction(true) as ChangeValidateAction);
                        navigate('/tools/tokenization');
                    } else {
                        setErrorText(i18n('Неверный логин или пароль'));
                    }
                }).catch((error) => {
                console.log(error)
                setErrorText(error.message);
            });
        } else {
            setErrorText(i18n('Не все поля заполнены'));
        }
        event.preventDefault();
    };

    const closeAlert = () => {
        setErrorText('');
    }

    const getAuthErrorMessage = (response: AuthError): string => {
        let msg = '';
        if (response.message) {
            msg = response.message;
        } else {
            if (response.errors && response.errors?.length > 0) {
                let err = response.errors[0];

                msg = err.id === 'DME0034' ? i18n('server_not_available') : err.message;
            }
        }

        return msg;
    }

    const dispatch = useDispatch();
    const [errorText, setErrorText] = useState('');

    return (
        <div>
            <SignBackground/>

            <div className={styles.form}>
                <div className={styles.part_left}>
                    <div className={styles.logo}>
                        <Logo/>
                        DAMASK
                    </div>

                    <div className={styles.slogan}>
                        <div className={styles.rectangle}/>


                        <div className={styles.text}>
                            БЕЗОПАСНАЯ ОБРАБОТКА ДАННЫХ В ПУБЛИЧНЫХ ОБЛАЧНЫХ СЕРВИСАХ
                        </div>
                    </div>

                </div>

                <Form className={styles.part_right}
                      loading={loadings}
                      title="Enter"
                      action={`${URL}"/v1/auth`}
                      submitHandler={(e: React.FormEvent<HTMLFormElement>) => submitHandler(e, dispatch, setErrorText)}>

                    <ConfigProvider
                        theme={{
                            components: {
                                Input: {
                                    colorPrimary: '#F75623',
                                    activeShadow: '0 0 0 1px #F75623',
                                    borderRadius: 4,
                                    algorithm: true,
                                },
                                Checkbox: {
                                    colorPrimary: '#F75623',
                                    algorithm: true,
                                },
                                Alert: {
                                    colorFill: '#D92020'

                                }
                            },
                        }}
                    >

                  <span className={styles.label + ' neutral_50 ' + styles.mulish_regular}>
                    {i18n('Login')}
                  </span>

                        <Input
                            id="email"
                            name="email"
                            type="text"/>

                        <div style={{marginTop: "22px"}}>
                  <span className={styles.label + ' neutral_50 ' + styles.mulish_regular}>
                    {i18n('Password')}
                  </span>

                            <div style={{marginTop: '4px'}}>
                                <Input.Password
                                    id="password"
                                    name="password"
                                    iconRender={(visible) => (visible ? <EyeFilled style={{fontSize: '20px', color: '#717681'}}/> :
                                        <EyeInvisibleFilled style={{fontSize: '20px', color: '#717681'}}/>)}/>
                            </div>

                        </div>
                        <div style={{marginTop: "12px"}}>
                            <Checkbox id="save-pass"
                                      name="save-pass">
                            <span className={styles.mulish_regular}>
                                Запомнить пароль
                            </span>

                            </Checkbox>
                        </div>

                        <span style={{marginTop: "24px"}}>
                             {errorText ?
                                 <Alert message={errorText}
                                        type="error"
                                        showIcon
                                        style={{height: "48px", fontFamily: "Mulish-Regular", fill: "#D92020"}}
                                        onClose={closeAlert}
                                        closable/>
                                 : <></>}

                        </span>

                    </ConfigProvider>
                </Form>

            </div>
        </div>

    );
}
