import {Card, ConfigProvider, Popover, Segmented} from 'antd';
import React, {FC, useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {G2} from '@ant-design/charts';
import {SettingOutlined, SyncOutlined} from '@ant-design/icons';
import styles from './Monitoring.module.scss';
import {MonitoringParam} from '../../types/Manitoring/MonitoringParam';
import {i18n} from '../../utils';
import {getMonitoringMetrics} from '../../services/pages/monitoring';
import {ChartData} from '../../types/Manitoring/ChartData';
import {ChartState} from '../../types/Manitoring/ChartState';
import {MonitoringChartSettingsPopup} from './MonitoringChartSettingPopup';


export type MonitoringServerChartProps = {
    className?: string;
    params?: MonitoringParam;
    containerId: string;
    labelY: string;
    lineColor: string;
    type: string;
};

export const MonitoringServerChart: FC<MonitoringServerChartProps> = ({
                                                                          type,
                                                                          containerId,
                                                                          labelY,
                                                                          lineColor
                                                                      }) => {

    const {id} = useParams();

    const [chart, setChart] = useState<any>();

    const [chartData, setChartData] = useState<ChartData[]>( );

    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);

    const [chartState, setChartState] = useState<ChartState>({
        period:'MINUTE',
        lineColor:lineColor,
        data: []
    });

    let segmentOpts = [
        {label: 'Месяцы', value: 'MONTH'},
        {label: 'Дни', value: 'DAY'},
        {label: 'Часы', value: 'HOUR'},
        {label: 'Минуты', value: 'MINUTE'}
    ]

    useEffect(()=> {
        getChartData();
    },[]);

    const buildChart = () => {
        const chart = new G2.Chart({
            container: containerId,
            width: 500,
            height: 210,
            marginLeft: -10
        });

        chart
            .data(chartState.data)
            .encode('x', 't')
            .encode('y', 'v')
            .scale('x', {
                range: [0, 1],
            })
            .scale('y', {
                nice: true,
            })
            .axis('y', {labelFormatter: (d: any) => d + labelY});

        chart
            .line()
            .legend({color: false})
            .axis('y', {title: ''})
            .axis('x', false)
            .style({
                stroke: chartState.lineColor,
                lineWidth: 3,
            })
            .encode('shape', 'smooth')
            .slider({
                x: {},
            });

        chart.render().then();
        setChart(chart);
    }

    useEffect(() => {
        buildChart();
    }, [ ]);

    useEffect(()=> {
        if (chart) {
        chart.changeData(chartData);
        chart.render();}
    }, [chartState.data]);

    useEffect(()=> {
        getChartData();
    }, [chartState.period]);

    useEffect(() => {
        getChartData();
    }, [id]);

    useEffect(() => {
        buildChart();
    }, [chartState.lineColor]);

    const getChartData=()=> {
        getMonitoringMetrics(id ||'', type, chartState.period, 100).then((resp) => {
            setChartData(resp.data);
            setChartState((prev)=> ({...prev, data: resp.data}));
        });
    }

    const onChangePeriod = (period: string) => {
        setChartState((prev)=>({...prev, period:period}));
    }

    const handlePopupSettings = (newOpen: boolean) => {
        setSettingsModalOpen(newOpen);
    };

    const onApplySettings = (color: string) => {
        setChartState((prev) => ({...prev, lineColor: color}));
        setSettingsModalOpen(false);

    }

    const onCancelSettingsHandler = () => {
        setSettingsModalOpen(false);

    }

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {

                        Input: {
                            colorPrimary: '#F75623',
                            activeShadow: '0 0 0 1px #F75623',
                            algorithm: true,
                        },
                        Table: {
                            headerBg: '#FFFFFF'
                        },
                        Card: {
                            borderRadius: 6,
                            colorPrimaryBorder: '#9BA0AA',
                            algorithm: true,
                        },
                        Radio: {
                            buttonBg: '#E5E7EB',
                            colorBorder: '#E5E7EB',
                            buttonColor: '#717681',
                            borderRadius: 16,
                            buttonCheckedBg: '#E5E7EB',
                            colorPrimaryBorder: '#E5E7EB',
                            buttonPaddingInline: 7
                        }
                    },
                }}>

                <Card bordered={true} style={{width: '594px', height: '312px', margin: '0 10px 0 0'}} key={'server_chart'}>
                    <div className={'header_2'} style={{position: 'absolute', top: '10px', left: '10px', width: '100%'}}>
                        <div>
                            {i18n(type)}
                            <span style={{float: 'right', marginRight: '20px'}} className={'button_2 primary_60'}>
                                <span>
                                    <SyncOutlined className={styles.chart_icon}
                                                  style={{marginRight: '5px'}}
                                                  onClick={getChartData}/> Обновить
                                </span>
                                <span>
                                    <Popover
                                        content={<MonitoringChartSettingsPopup
                                            isOpen={isSettingsModalOpen}
                                            currentLineColor={chartState.lineColor}
                                            onApplySettings={onApplySettings}
                                            onCancelHandler={onCancelSettingsHandler}
                                            containerId={containerId}/>}
                                        getPopupContainer={() => document.getElementById(containerId)!}
                                        placement="bottomRight"
                                        title="Настройки"
                                        trigger="click"
                                        open={isSettingsModalOpen}
                                        onOpenChange={handlePopupSettings}>
                                    <SettingOutlined
                                        className={styles.chart_icon}
                                        style={{marginLeft: '10px', marginRight: '5px', cursor: 'pointer'}}/>
                                    </Popover> Настроить
                                </span>
                        </span>
                        </div>

                    </div>
                    <div style={{marginTop: '10px', marginBottom: '10px'}}>

                        <Segmented<string>
                            options={segmentOpts}
                            defaultValue={chartState.period}
                            onChange={(value) => onChangePeriod(value)}
                        />
                    </div>
                    <div id={containerId} style={{marginTop: '10px'}}/>

                </Card>
            </ConfigProvider>
        </>
    )
}
