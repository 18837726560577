import React, {FC, useEffect, useState} from 'react';
import {Alert, AlertProps} from 'antd';


export type AntAlertProps = {
    severity: string;
    content?: string;
    show: boolean;
    onClose?: () => void;
};

export const AntAlert: FC<AntAlertProps> = ({
                                                severity,
                                                content,
                                                show,
                                                onClose
                                            }) => {

    const [alertType, setAlertType] = useState<AlertProps['type']>(undefined);

    useEffect(() => {
        switch (severity) {
            case 'success':
                setAlertType('success');
                break;
            case 'error':
                setAlertType('error');
                break;
            case 'warning':
                setAlertType('warning');
                break;
            case 'info':
                setAlertType('info');
                break;

            default:
                setAlertType(undefined);
        }
    });

    return (
        <React.Fragment>
            {show ? <Alert type={alertType}
                           onClose={onClose}
                           className={'body_1_long neutral_90'}
                           showIcon
                           closable
                           message={content}/> : <></>}
        </React.Fragment>
    )
}
