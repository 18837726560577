import React, {FC} from 'react';
import {ConfigProvider, Modal} from 'antd';


export type DeleteModalProps = {


    title: string;
    content?: string;
    isModalOpen: boolean;
    popupContainer: string;
    onConfirmHandler?: () => void;
    onCancelHandler?: () => void;

};

export const DeleteModal: FC<DeleteModalProps> = ({
                                                      title,
                                                      content,
                                                      isModalOpen,
                                                      popupContainer,
                                                      onConfirmHandler,
                                                      onCancelHandler
                                                  }) => {

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    }
                },
            }}>

            <Modal title={title}
                   visible={isModalOpen}
                   onOk={onConfirmHandler}
                   onCancel={onCancelHandler}
                   cancelText={'Отменить'}
                   okText={'Удалить'}
                   getContainer={() => document.getElementById(popupContainer)!}>

                <p>{content}</p>

            </Modal>


        </ConfigProvider>
    )
}


