import React, {FC, useEffect, useState} from 'react';
import {Alert} from '@mui/material';
import {i18n} from '../../utils';


export type CustomerAlertProps = {
    id?: string;
    severity: string;
    content?: string;
    action: string;
    show: boolean;
    onClose?: () => void;

};

export const CustomerAlert: FC<CustomerAlertProps> = ({
                                                          id,
                                                          severity,
                                                          action, show,
                                                          content,
                                                          onClose
                                                      }) => {

    const [isSuccess, setSuccess] = useState(false);
    const [hasError, setError] = useState(false);

    useEffect(() => {
        switch (severity) {
            case 'success':
                setSuccess(true);
                setError(false);
                break;
            case 'error':
                setSuccess(false);
                setError(true);
                break;

            default:
                setSuccess(false);
                setError(false);
        }
    });

    return (
        <React.Fragment>
            {show && isSuccess ? <Alert severity='success' onClose={onClose}>
                Запись успешно {i18n(action || '')}.
                <p>{content}</p>
            </Alert> : <></>}

            {show && hasError ? <Alert severity='error' onClose={onClose}>
                Ошибка. Запись не {i18n(action || '')}.
                <p>{content}</p>
            </Alert> : <></>}
        </React.Fragment>
    )
}
