export enum OutputMessageType {
    TEXT="TEXT",
    LINES="LINES",
    JSON_LIST="JSON_LIST",
    JSON_SINGLE = "JSON_SINGLE",
    INTERACTIVE="INTERACTIVE",
    COMPLETE="COMPLETE",
    UPDATE_PROMPT="UPDATE_PROMPT",
    HISTORY="HISTORY",
    ERROR="ERROR"
}
