import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {DictionariesAll} from './DictionariesAll';

export function Dictionaries() {

    const {pathname} = useLocation();

    return (
        <>
            {pathname === '/control/dictionaries' ? (
                <> <DictionariesAll/> </>
            ) : (
                <>
                    <Outlet/>
                </>
            )}
        </>

    );
}
