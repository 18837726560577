import styles from './Dictionaries.module.scss';
import * as React from 'react';
import {ChangeEvent, useEffect, useState} from 'react';

import {getUser} from '../../services/pages/users';
import {i18n} from '../../utils';
import {DictionaryItem} from '../../types/DictionaryItem';
import {Card, ConfigProvider, Input} from 'antd';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {useParams} from 'react-router';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';
import {createDictionary} from '../../services/pages/dictionary';

const {TextArea} = Input;


export function Dictionary() {

    const [submitting, setSubmitting] = useState(false);

    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});

    const roles = [{label: 'user', value: 'user'}, {label: 'admin', value: 'admin'}];

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const {id} = useParams();

    useEffect(() => {
        if (id) {
            getUser(id || '').then((response) => {
                    setDictionary(response);
                }
            ).catch((err) => console.log(err))
        }
    }, []);


    const [dictionary, setDictionary] = useState<DictionaryItem>();

    const create = () => {
        setErrors(validateValues(dictionary));
        setSubmitting(true);
    }

    const validateValues = (dictionary: DictionaryItem | undefined) => {

        let errors = {
            name: "",
            description: ""
        };
        if (!dictionary?.name) {
            errors.name = "Необходимо заполнить поле";
        }

        if (!dictionary?.description) {
            errors.description = "Необходимо заполнить поле";
        }

        return errors;
    };

    const [errors, setErrors] = useState({
        name: "",
        description: ""
    });

    useEffect(() => {
        if (!hasErrors() && submitting) {
            finishSubmit();
        }
    }, [errors]);

    const hasErrors = () => {
        let res = false;
        if (errors.name) res = true;
        if (errors.description) res = true;
        return res;
    }

    const finishSubmit = () => {
        saveUser(dictionary!)
    }

    const saveUser = (dictionary: DictionaryItem) => {

        createDictionary(dictionary)
            .then((res) => {
                handleResponse(res, 'create');
            }).catch((error) => {
            setAntAlertProps({
                severity: 'error',
                content: i18n('create_user_error') + error,
                show: true
            })
        });

    }


    const onInputChange = (e: React.ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        console.log('CHANGE_HAndler');
        console.log(e.target.value);
        console.log(e.target.name);
        const value = e.target.value;
        setDictionary({
            id: "", name: "", description: "",
            ...dictionary, [e.target.name]: value
        })
        // setDictionary({
        //     ...dictionary, [e.target.name]: value
        // })
    }

    const handleResponse = (res: any, action: string) => {

        if (res.errors != undefined && res.errors.length > 0) {
            setAntAlertProps({
                severity: 'error',
                content: i18n(action + '_user_error') + dictionary?.name + ' ' + res.errors[0].message,
                show: true
            });
        } else {
            setAntAlertProps({
                severity: 'success',
                content: i18n(action + '_user_success') + dictionary?.name + ' ',
                show: true
            });
        }
    }

    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }


    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',

                        algorithm: true,
                    }
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%'}} key={'dictionary'}>
                <div id="dictionary_page" className={styles.page}>

                    <div className="header_1">
                        {id ? 'Редактирование словаря' : 'Добавление словаря'}

                        <div className={styles.float_right}>
                         <span style={{marginRight: '10px'}}>

                         </span>
                            <ActionButton btnText={'Сохранить'}
                                          htmlType={'submit'}
                                          type={'primary'}
                                          onClickHandler={create}
                            />
                        </div>
                    </div>


                    <div className={styles.form}>

                        <div className={styles.form_left}>

                            <div>
                                <div style={{marginBottom: '4px'}}>
                                    Название
                                    <span className={styles.required}> * </span>
                                </div>

                                <Input name="name"
                                       value={dictionary?.name}
                                       onChange={(e) => onInputChange(e)}/>
                                {errors.name ? (
                                    <p className={styles.error}> {errors.name} </p>
                                ) : null}
                            </div>


                            <div>
                                <div style={{marginTop: '24px', marginBottom: '4px'}}>
                                    Описание
                                    <span className={styles.required}> * </span>
                                </div>

                                <Input name="description"
                                       value={dictionary?.description}
                                       onChange={(e) => onInputChange(e)}/>
                                {errors.description ? (
                                    <p className={styles.error}> {errors.description} </p>
                                ) : null}
                            </div>

                        </div>
                        <div className={styles.form_right}>
                            Состав словаря
                        </div>
                    </div>

                    <div className={styles.notification}>

                        <AntAlert severity={antAlertProps.severity}
                                  content={antAlertProps.content}
                                  onClose={() => alertClose()}
                                  show={antAlertProps.show}/>
                    </div>
                </div>


            </Card>
        </ConfigProvider>
    );
}
