import styles from "./Tokenization.module.scss";
import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {tokenize} from '../../services/pages/tokenize'
import {TokenizeForm} from '../../types/Forms/TokenizeForm';
import {JSONSchema7} from "json-schema";
import {Card} from 'antd';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {TokenizeInfo} from '../../components/TokenizeInfo/TokenizeInfo';
import {CodeEditor} from '../../components/CodeEditor/CodeEditor';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';

export function Tokenization() {


    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});


    const [submitting, setSubmitting] = useState(false);

    const [tokenizeResult, setTokenizeResult] = React.useState([]);
    const [isHidden, setIsHidden] = React.useState(true);


    const [tokenizeForm, setTokenizeForm] = useState<TokenizeForm | undefined>({
        group: "",
        values: []
    });

    const [defaultCodeEditorValue, setDefValue] = useState({
        values: [""],
        group: ""
    });

    const [cleanCounter, setCleanCounter] = useState<number>(1);


    const doTokenize = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors(validateValues(tokenizeForm));
        setSubmitting(true);
    }

    const validateValues = (tForm: TokenizeForm | undefined) => {
        let errors = {
            values: "",
            group: ""
        };
        if (!tForm?.values) {
            errors.values = "values обязательное к заполнению поле";
        }
        if (tForm?.group?.length == 0) {
            errors.group = "group Обязательное к заполнению поле";
        }
        return errors;
    };

    const [errors, setErrors] = useState({
        values: "",
        group: ""
    });


    useEffect(() => {
        if (!hasErrors() && submitting) {
            finishSubmit();
        }

        if (hasErrors()) {
            setAntAlertProps({severity: 'error', content: 'Ошибка токенизации', show: true});
        }
    }, [errors]);


    const hasErrors = () => {
        let res = false;
        if (errors.values) res = true;
        if (errors.group) res = true;
        return res;
    }

    const finishSubmit = () => {

        tokenize(tokenizeForm).then((res) => {
            if (res.errors != undefined && res.errors.length > 0) {
                setAntAlertProps({severity: 'error', content: 'Ошибка токенизации', show: true})
                setIsHidden(true);
            } else {
                setAntAlertProps({severity: 'success', content: 'Запись успешно токенезирована', show: true})
                setIsHidden(false);
                setTokenizeResult(res);
            }
        }).catch((error) => {
            setAntAlertProps({severity: 'error', content: 'Ошибка токенизации', show: true})
            setIsHidden(true);
        });

    }


    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }

    const handleCodeEditorOnChange = (val: string) => {
        try {
            let form = JSON.parse(val);
            setTokenizeForm(form);
        } catch (e) {
            //to do not valid form
            console.log(e);
        }
    }

    const clearValues = () => {
        let counter = cleanCounter + 1;
        setCleanCounter(counter);
        if (cleanCounter % 2 == 0) {
            setDefValue({
                values: [""],
                group: ""
            });
        } else {
            setDefValue({
                values: [" "],
                group: " "
            });
        }
    }

    const copyHandler = () => {
        if (tokenizeResult.length != 0) {
            navigator
                .clipboard
                .writeText(JSON.stringify(tokenizeResult, null, 2))
                .then(() =>
                    setAntAlertProps({severity: 'success', content: 'Результат токенизации скопирован в буфер обмена', show: true})
                );
        }
    }

    const tokenization_schema = {
        type: "object",
        maxProperties: 2,
        minProperties: 2,
        title: "tokenization",
        properties: {
            values: {
                type: "array",
                items: {
                    type: "string"
                }
            },
            group: {
                type: "string"
            }
        },
        required: ["values", "group"]
    };

    return (
        <Card bordered={false} style={{width: '100%', height: '100%'}} key={'tokenization'}>

            <div className={styles.page}>

                <div className="header_1">
                    Токенизация
                </div>

                <div className={styles.form}>

                    <div className={styles.form_left}>

                        <div className='card-body'>
                            <form onSubmit={(e) => doTokenize(e)}>
                                <div>

                                    <div style={{marginBottom: '5px'}}>
                                        <label>Значение для токенизации
                                            <span className={styles.error}>
                                                *
                                            </span>
                                        </label>
                                    </div>

                                    <CodeEditor defaultValue={defaultCodeEditorValue}
                                                editable={true}
                                                dataSchema={tokenization_schema as JSONSchema7}
                                                onChangeHandler={handleCodeEditorOnChange}/>

                                </div>

                                <div style={{marginTop: '10px'}}>

                                    <ActionButton btnText={'Токенизировать'}
                                                  htmlType={'submit'}
                                                  type={'primary'}/>

                                    <span style={{marginLeft: '16px'}}>
                                    <ActionButton btnText={'Копировать результат'}
                                                  onClickHandler={copyHandler}
                                                  className={'button_1 primary_80'}
                                                  htmlType={'button'}
                                                  type={'text'}/>

                                    </span>

                                    <span style={{marginLeft: '16px'}}>
                                    <ActionButton btnText={'Очистить значения'}
                                                  onClickHandler={clearValues}
                                                  className={'button_1 primary_80'}
                                                  htmlType={'button'}
                                                  type={'text'}/>
                                    </span>
                                </div>
                            </form>

                        </div>
                    </div>

                    <div className={styles.form_right}>
                        <div hidden={!isHidden}>
                            <div style={{marginBottom: '5px'}}>
                                <label>

                                </label>
                            </div>

                            <TokenizeInfo action={'Токенизировать'} formFields={'values'}/>
                        </div>

                        <div hidden={isHidden}>
                            <div style={{marginBottom: '5px'}}>
                                <label>
                                    Результат
                                </label>
                            </div>
                            <CodeEditor defaultValue={tokenizeResult} editable={false}/>
                        </div>
                    </div>
                </div>

                <div className={styles.notification}>

                    <AntAlert severity={antAlertProps.severity}
                              content={antAlertProps.content}
                              onClose={() => alertClose()}
                              show={antAlertProps.show}/>
                </div>
            </div>
        </Card>
    );
}
