export class CliInteractiveOption {
    name: string;
    value: string;
    hide_input?:boolean;

    constructor(name: string, value: string) {

        this.name = name;
        this.value = value;
    }
}
