import {CliInteractiveOption} from './CliInteractiveOption';
import {CliCommandType} from './CliCommandType';

export class Message {
    command: string | null;
    type: CliCommandType;
    interactive_options?: CliInteractiveOption[];

    constructor(command: string | null, type: CliCommandType, interactiveOptions?: CliInteractiveOption[] | null) {
        this.command = command;
        this.type = type;
        if (interactiveOptions) {
            this.interactive_options = interactiveOptions;
        }
    }

}
