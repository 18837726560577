import styles from './UsersNew.module.scss';
import React, {useEffect, useState} from 'react';

import {deleteUser, getUsers, getUsersPageable} from '../../services/pages/users';
import {UsersResponse} from '../../types/UsersResponse';
import {CustomerAlert, CustomerAlertProps} from '../../components/CustomerAlert/CustomerAlert';
import {NavButton} from '../../components/NavButton/NavButton';
import {Card, ConfigProvider, Table} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {doNavigate} from '../../utils';

import {useNavigate} from 'react-router-dom';
import {SearchState} from '../../types/SearchState';
import {NoFilterData} from '../../components/NoFilterData/NoFilterData';
import {SearchInput} from '../../components/SearchInput/SearchInput';
import {map} from 'lodash';
import {TableFilterItem} from '../../types/TableFilterItem';
import {FilterItem} from '../../types/FilterItem';
import {TableDropdownFilter} from '../../components/TableDropdownFilter/TableDropdownFilter';

const defaultEditUrl = '/control/users/edit/';

export function UsersAll() {

    const [data, setData] = useState<UsersResponse['items']>([]);

    const [customAlertProps, setAlertProps] = useState<CustomerAlertProps>({severity: '', action: '', show: false});

    const [count, setCount] = useState<number>();
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);

    const [nameFilter, setNameFilter] = useState<TableFilterItem[]>( []);
    const [nameFilterItems, setNameFilterItems] = useState<FilterItem[]>( []);
    const [nameFiltered, setNameFiltered] = useState<string[]| null>( []);

    const [displayNameFilter, setDisplayNameFilter] = useState<TableFilterItem[]>( []);
    const [displayNameFilterItems, setDisplayNameFilterItems] = useState<FilterItem[]>( []);
    const [displayNameFiltered, setDisplayNameFiltered] = useState<string[]>( []);


    const [emailFilter, setEmailFilter] = useState<TableFilterItem[]>( []);
    const [emailFilterItems, setEmailFilterItems] = useState<FilterItem[]>( []);
    const [emailFiltered, setEmailFiltered] = useState<string[]| null>( []);

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState<string>('');

    const [searchState, setSearchState] = useState<SearchState>({
        filteredInfo: null,
        sortedInfo: null,
        filteredData: [],
        filtered: false,
        searchText: ""
    });

    const locale = {

        emptyText: (
            <NoFilterData/>
        )
    };


    useEffect(() => {
        loadUsersData();
    }, []);

    useEffect(()=> {
        initFilter();
    }, [data]);

    const fetchData = () => {
        getUsers().then((response) => {
                console.log("--response--");
                console.log(response);
                setData(response['items']);
            }
        ).catch((err) => console.log(err))
    }

    const loadUsersData = () => {
        if (loading) {
            return;
        }
        setLoading(true);

        getUsersPageable(limit, offset).then((response) => {
                let count = response['count']
                setCount(count);
                let groups = response['items'];
                setData((data) => [...data, ...groups]);
                setLoading(false);
                setOffset(offset + limit);
            }
        ).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }


    const onDeleteHandler = (userId: string): void => {
        deleteUser(userId)
            .then((res) => {
                console.log(res);
                fetchData();
                setAlertProps({severity: 'success', action: 'Deleted', show: true});

            })
            .catch((err) => {
                console.log(err);
                setAlertProps({severity: 'error', action: 'Deleted', show: true});

            });
    }

    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("On_CHANGE");
        console.log(event.target.value);
        updateSearchState(event.target.value);

    }

    const clearSearchInput = () => {
        console.log('CLEAR_SEARCH_INPUT');

        updateSearchState('');
    }


    const updateSearchState = (searchText: string) => {


        setSearchValue(searchText);
        Object.assign(searchState, {searchText: searchText});

        console.log("On_CHANGE_STATE");
        console.log(searchState.searchText)


        const filtered = map(data, record => {
            const nameMatch = record.username.includes(searchText);
            const displayNameMatch = record.display_name.includes(searchText);
            const descriptionMath = record.description?.includes(searchText);
            const emailMatch = record.email.includes(searchText);


            if (!nameMatch && !displayNameMatch && !descriptionMath && !emailMatch) {
                return null;
            }
            return record;
        }).filter(record => !!record);

        Object.assign(searchState, {filteredData: searchState.searchText ? filtered : data});
        console.log(searchState.filteredData);
    }


    const initFilter=() => {


        let names = data?.map((user)=> user.username);
        let emails = data?.map((user)=> user.email);
        let displayNames = data?.map((user)=> user.display_name);



        names = names?.filter((value, index) => names?.indexOf(value) === index);
        emails = emails?.filter((value, index) => emails?.indexOf(value) === index);
        displayNames = displayNames?.filter((value, index) => displayNames?.indexOf(value) === index);





        const nameFilter = names?.map((val) => ({text: val, value: val}));
        const nameFilterItem = names?.map((val) => ({label: val, value: val}));

        const emailFilter = emails?.map((val) => ({text: val, value: val}));
        const emailFilterItem = emails?.map((val) => ({label: val, value: val}));

        const displayNamesFilter = displayNames.map((val) => ({text: val, value: val}));
        const displayNamesFilterItem = displayNames.map((val) => ({label: val, value: val}));



        setNameFilterItems([...nameFilterItem]);
        setNameFilter([...nameFilter]);

        setEmailFilter([...emailFilter]);
        setEmailFilterItems([...emailFilterItem]);

        setDisplayNameFilter([...displayNamesFilter]);
        setDisplayNameFilterItems([...displayNamesFilterItem]);

        console.log(nameFilterItem);
        console.log(nameFilter);
        console.log(nameFiltered)
    }

    const applyNameFilter = (filteredValues: string[]) => {
        console.log('apply_main');
        setNameFiltered([...filteredValues]);
    }

    const applyDisplayNameFilter = (filteredValues: string[]) => {
        console.log('apply_main');
        setDisplayNameFiltered([...filteredValues]);
    }

    const applyEmailFilter = (filteredValues: string[]) => {
        console.log('apply_main');
        setEmailFiltered([...filteredValues]);
    }


    const columns = [
        {
            title: 'Имя',
            dataIndex: 'username',
            key: 'user_name',
            width: 240,
            filterDropdown: <TableDropdownFilter
                popupId={'scrollableDiv_2'}
                onApply={applyNameFilter}
                items={nameFilterItems}/>,
            filters: nameFilter,
            filteredValue: nameFiltered,
            onFilter: (value:any, record:any) => record.username.includes(value),
        },
        {
            title: 'Отображаемое имя',
            dataIndex: 'display_name',
            key: 'user_display_name',
            width: 240,
            filterDropdown: <TableDropdownFilter
                popupId={'scrollableDiv_2'}
                onApply={applyDisplayNameFilter}
                items={displayNameFilterItems}/>,
            filters: displayNameFilter,
            filteredValue: displayNameFiltered,
            onFilter: (value:any, record:any) => record.username.includes(value),
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'user_description',
            width: 392
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'user_email',
            width: 260,
            filterDropdown: <TableDropdownFilter
                popupId={'scrollableDiv_2'}
                onApply={applyEmailFilter}
                items={emailFilterItems}/>,
            filters: emailFilter,
            filteredValue: emailFiltered,
            onFilter: (value:any, record:any) => record.email.includes(value),
        }, {
            title: 'Роли',
            dataIndex: 'roles',
            key: 'user_roles',
            width: 260,
        }

    ]

    const alertClose = () => {
        setAlertProps({severity: '', action: '', show: false});
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        algorithm: true,
                    },
                    Table: {
                        headerBg: '#FFFFFF',
                        colorPrimary:'#F75623',
                        algorithm: true
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder:'#D92020',
                        algorithm: true,
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        algorithm: true,
                    },
                },
            }}
        >
            <Card bordered={false} style={{width: '100%', height: '100%'}} key={'usersAll'}>
                <div className={styles.page}>


                    <div className={'header_1'}>
                        Пользователи

                        <SearchInput searchValue={searchValue}
                                     onChangeHandler={handleSearchInput}
                                     onClearSearchInput={clearSearchInput}/>

                    </div>
                    <div className={'header_2'}>

                        {offset < count! ? offset + ' из ' + count : count + ' из ' + count}
                    </div>


                    <div className={styles.form}>
                        <CustomerAlert action={customAlertProps.action}
                                       severity={customAlertProps.severity}
                                       show={customAlertProps.show}
                                       onClose={() => alertClose()}/>


                        <div id="scrollableDiv_2" className={'scrollable_div'}>
                            <InfiniteScroll
                                dataLength={data.length}
                                next={loadUsersData}
                                hasMore={data.length < count!}
                                scrollThreshold={1}
                                style={{overflow:"unset"}}
                                scrollableTarget="scrollableDiv_2"
                                loader={<h6></h6>}
                                >

                                <Table
                                    key={'users_table'}
                                    id="users_table"
                                    dataSource={searchState.searchText ? searchState.filteredData : data}
                                    columns={columns}
                                    rowKey={'id'}
                                    locale={locale}
                                    pagination={false}
                                    size="small"
                                    getPopupContainer={()=>document.getElementById('users_table')!}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: () => {

                                                console.log(record);

                                                doNavigate(defaultEditUrl + `${record.id}`, navigate);

                                            }, style: {
                                                cursor: 'pointer'
                                            }
                                        };
                                    }}
                                    sticky={true}
                                />

                            </InfiniteScroll>
                        </div>

                    </div>

                </div>
                <div className={styles.nav_btn}>

                    <NavButton route={'/control/users/create'}
                               btnText={'Добавить пользователя'}
                               className={'button_1'}/>
                </div>
            </Card>
        </ConfigProvider>
    );
}
