import React, {FC} from 'react';
import {ConfigProvider, Input} from 'antd';
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import styles from './SearchInput.module.scss';

export type SearchInputProps = {
    searchValue?: string;
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClearSearchInput?: () => void;

};

export const SearchInput: FC<SearchInputProps> = ({
                                                      searchValue,
                                                      onChangeHandler,
                                                      onClearSearchInput
                                                  }) => {

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        algorithm: true,
                    },
                },
            }}>

            <div className={styles.search_input_style +' body_1_short neutral_50'}>
                <Input placeholder="Поиск"
                       onChange={onChangeHandler}
                       value={searchValue}
                       suffix={!searchValue ? <SearchOutlined/> : <CloseOutlined onClick={onClearSearchInput}/>}/>
            </div>

        </ConfigProvider>
    )
}


