import React, {FC, useEffect, useState} from 'react';
import styles from './MaskingRules.module.scss';
import {Checkbox, ConfigProvider, Input, Modal, Select} from 'antd';
import {MRuleType} from '../../types/MaskingRule/MRuleType';
import {DictionaryItem} from '../../types/DictionaryItem';
import {uuid} from '../../utils';
import {MRuleTypeSchema} from '../../types/MaskingRule/MRuleTypeSchema';
import {LabelInValue} from '../../types/LabelInValue';
import {CheckboxChangeEvent} from 'antd/es/checkbox';


export type MaskingRuleSchemaModalProps = {

    className?: string;
    isModalOpen: boolean;
    title: string
    onCancelHandler: () => void;
    onConfirmHandler: (mRuleTypeSchema: MRuleTypeSchema) => void;
    ruleTypes?: MRuleType[];
    dictionary?: DictionaryItem[];
    editSchema?: MRuleTypeSchema | null;


};
export const MaskingRuleSchemaModal: FC<MaskingRuleSchemaModalProps> = ({
                                                                            isModalOpen,
                                                                            title,
                                                                            onCancelHandler,
                                                                            onConfirmHandler,
                                                                            ruleTypes,
                                                                            dictionary,
                                                                            editSchema
                                                                        }) => {
    const [submitting, setSubmitting] = useState(false);

    const initialMRuleTypeSchema = {
        rowId: uuid(),
        entity_name: "",
        masking_rule_type_id: ""
    }

    const [errors, setErrors] = useState({
        name: "",
        type: "",
        dictionary: ""
    });

    useEffect(() => {
        reset();
    }, [isModalOpen]);


    const [mRuleTypeSchema, setMRuleTypeSchema] = useState<MRuleTypeSchema>(initialMRuleTypeSchema);


    const onSave = () => {
        setSubmitting(true);
        const errors = validateValues(mRuleTypeSchema);
        setErrors(errors);
    }

    useEffect(() => {
        if (!hasErrors() && submitting) {
            finishSubmit();
        }
    }, [errors]);

    const finishSubmit = () => {
        onConfirmHandler(mRuleTypeSchema);
    }

    const hasErrors = () => {
        let res = false;

        if (errors.name) {
            res = true;
        }
        if (errors.type) {
            res = true;
        }
        if (errors.dictionary) {
            res = true;
        }

        return res;
    }

    const validateValues = (mRuleTypeSchema: MRuleTypeSchema) => {

        let errors = {
            name: "",
            type: "",
            dictionary: ""
        };
        if (!mRuleTypeSchema?.entity_name) {
            errors.name = "Необходимо заполнить поле";
        }

        if (!mRuleTypeSchema?.masking_rule_type_id) {
            errors.type = "Необходимо выбрать значение";
        }

        if (mRuleTypeSchema?.masking_rule_type_id === 'dictionary' && !mRuleTypeSchema?.masking_rule_params?.dictionary_id) {
            errors.dictionary = "Необходимо выбрать значение";
        }

        return errors;
    };

    const reset = () => {
        if(editSchema){
            setMRuleTypeSchema(editSchema);
        } else {
            setMRuleTypeSchema({...initialMRuleTypeSchema});
        }
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMRuleTypeSchema((prev) => ({...prev, entity_name: e.target.value}));
    }

    const handleRuleTypeChange = (val: any) => {
        let selected = val as LabelInValue;
        setMRuleTypeSchema((prev) => ({...prev, masking_rule_type_id: selected.value}));
        setMRuleTypeSchema((prev) => ({...prev, mRuleTypeName: selected.label}));

    };

    const handleDictChange = (val: any) => {


        let selected = val as LabelInValue;

        setMRuleTypeSchema((prev) => ({
            ...prev,
            masking_rule_params: {...prev.masking_rule_params, dictionary_id: selected.value}
        }));

        setMRuleTypeSchema((prev) => ({...prev, mRuleSchemaDictName: selected.label}));
    };

    const onCheckboxChange = (e: CheckboxChangeEvent) => {

        setMRuleTypeSchema((prev) => ({
            ...prev,
            masking_rule_params: {...prev.masking_rule_params, keep_mapping: e.target.checked}
        }));
    }

    const onKeepLength = (e: CheckboxChangeEvent) => {
        setMRuleTypeSchema((prev) => ({
            ...prev,
            masking_rule_params: {...prev.masking_rule_params, keep_original_length: e.target.checked}
        }));
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                        colorBorder: '1px solid #F75623',
                        colorPrimaryBorder: '1px solid #F75623',
                        algorithm: true
                    },
                    Input: {
                        colorPrimary: '#F75623',
                        borderRadius: 4,
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        boxShadow: ' 1px #F75623',
                        colorText: '#23262D',
                        colorTextPlaceholder: '#717681',

                        algorithm: true,
                    },
                    Modal: {
                        colorPrimary: '#F75623',
                        colorBorder: '2px solid #F75623',
                        colorPrimaryBorder: '2px solid #F75623',
                        algorithm: true
                    },
                    Switch: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    }

                },
            }}>
            <Modal title={<span className={'header_2 neutral_90'}> {title} </span>}
                   open={isModalOpen}
                   width={560}
                   onOk={onSave}
                   onCancel={onCancelHandler}
                   cancelText={'Отменить'}
                   okText={'Сохранить'}
                   getContainer={() => document.getElementById("maskingRule")!}>

                <div>
                    <div style={{width: '100%'}}>
                        <div className={'body_1_long neutral_40'} style={{marginTop: '20px'}}>
                            Название
                            <span className={styles.required}>
                            *
                        </span>
                        </div>
                        <div style={{marginTop: '10px'}}>

                            <Input name="name"
                                   onChange={(e) => onInputChange(e)}
                                   value={mRuleTypeSchema.entity_name}/>
                            {errors.name ? (
                                <p className={styles.error}> {errors.name} </p>
                            ) : null}
                        </div>

                        <div className={'body_1_long neutral_40'} style={{marginTop: '17px'}}>
                            Тип
                            <span className={styles.required}>
                            *
                        </span>
                        </div>
                        <div className={styles.popup_select_div} style={{marginTop: '10px'}}>

                            <Select
                                labelInValue
                                style={{width: '100%'}}
                                placeholder={'Выберите из списка'}
                                value={mRuleTypeSchema.masking_rule_type_id}
                                onChange={handleRuleTypeChange}
                                options={ruleTypes?.map((rType) => ({label: rType.name, value: rType.id || ''}))}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                showSearch/>

                        </div>
                        {errors.type ? (
                            <p className={styles.error}> {errors.type} </p>
                        ) : null}

                        <div className={'body_1_long neutral_40'}
                             style={{marginTop: '10px'}}
                             hidden={mRuleTypeSchema.masking_rule_type_id != 'dictionary'}>
                            Словарь
                            <span className={styles.required}>
                            *
                        </span>
                        </div>
                        <div className={styles.popup_select_div} hidden={mRuleTypeSchema.masking_rule_type_id != 'dictionary'}>

                            <Select
                                labelInValue
                                style={{width: '100%'}}
                                placeholder={'Выберите из списка'}
                                value={mRuleTypeSchema?.masking_rule_params?.dictionary_id}
                                onChange={handleDictChange}
                                options={dictionary?.map((dict) => ({label: dict.name, value: dict.id || ''}))}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                                showSearch/>

                        </div>
                        {errors.dictionary ? (
                            <p className={styles.error}> {errors.dictionary} </p>
                        ) : null}

                        <div className={styles.margin_20 + ' body_1_long neutral_90'}
                             hidden={mRuleTypeSchema.masking_rule_type_id != 'dictionary'}>

                            <Checkbox checked={mRuleTypeSchema?.masking_rule_params?.keep_mapping}
                                      name="keep_mapping"
                                      onChange={(e) => onCheckboxChange(e)}/>
                            <span style={{marginLeft: '5px'}}> Keep user-token-value mappings</span>


                        </div>


                        <div className={styles.margin_20 + ' body_1_long neutral_90'}

                             hidden={mRuleTypeSchema.masking_rule_type_id != 'default'}>
                            <Checkbox name="keep_original_length"
                                      checked={mRuleTypeSchema.masking_rule_params?.keep_original_length}
                                      onChange={(e) => onKeepLength(e)}/>
                            <span style={{marginLeft: '5px'}}> Keep original string length </span>

                        </div>

                    </div>
                </div>
            </Modal>

        </ConfigProvider>
    );
};
