import React, {FC, useEffect, useState} from 'react';
import {Checkbox, ConfigProvider, Input, Select} from 'antd';
import styles from '../MaskingRules/MaskingRules.module.scss';
import {MRule} from '../../types/MaskingRule/MRule';
import {MRuleType} from '../../types/MaskingRule/MRuleType';
import {DictionaryItem} from '../../types/DictionaryItem';

const {TextArea} = Input;


export type CommonRuleInfoProps = {


    mRule?: MRule;
    isValidate: boolean;
    ruleTypes: MRuleType[];
    dictionary: DictionaryItem[];
    onInputChange: (event: any) => void;
    onTypeChange: (event: any) => void;
    onDefaultDictionaryChange: (event: any) => void;
    onDefaultParamChange: (event: any) => void;
    onValidateFinishHandler: (hasError: boolean) => void;

};

export const CommonRuleInfo: FC<CommonRuleInfoProps> = ({
                                                            isValidate,
                                                            mRule,
                                                            ruleTypes,
                                                            dictionary,
                                                            onInputChange,
                                                            onTypeChange,
                                                            onDefaultParamChange,
                                                            onDefaultDictionaryChange,
                                                            onValidateFinishHandler


                                                        }) => {

    const [errors, setErrors] = useState({
        name: "",
        defaultType: "",
        defaultDictionary: ""
    });


    useEffect(() => {

        if (isValidate) {
            const err = validateValues(mRule);
            Object.assign(errors, {name: err.name});
            Object.assign(errors, {defaultType: err.defaultType});
            Object.assign(errors, {defaultDictionary: err.defaultDictionary});
            onValidateFinishHandler(hasErrors());
        }
    }, [isValidate]);

    const validateValues = (mRule: MRule | undefined) => {

        let errors = {
            name: "",
            defaultType: "",
            defaultDictionary: ""
        };
        if (!mRule?.name) {
            errors.name = "Необходимо заполнить поле";
        }
        if (!mRule?.default_masking_rule_type_id) {
            errors.defaultType = "Необходимо выбрать значение";
        }
        if (mRule?.default_masking_rule_type_id === 'dictionary' && !mRule?.default_masking_rule_params?.dictionary_id) {
            errors.defaultDictionary = "Необходимо выбрать значение";
        }


        return errors;
    };

    const hasErrors = () => {
        let res = false;
        if (errors.name) res = true;

        return res;
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <ConfigProvider
            theme={{
                components: {
                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder: '#D92020',
                        algorithm: true,
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        algorithm: true
                    }
                },
            }}>

            <div className={styles.form}>

                <div className={styles.form_left}>

                    <div>
                        <div style={{marginBottom: '4px'}}>
                            Название
                            <span className={styles.required}>
                            *
                        </span>
                        </div>
                        <Input name="name"
                               value={mRule?.name}
                               onChange={(e) => onInputChange(e)}/>
                        {errors.name ? (
                            <p className={styles.error}> {errors.name} </p>
                        ) : null}
                    </div>


                    <div className={'body_1_long'} style={{marginTop: '20px', marginBottom: '4px'}}>
                        Тип по умолчанию
                        <span className={styles.required}>
                            *
                        </span>
                    </div>
                    <div>

                        <Select

                            labelInValue
                            style={{width: '100%'}}
                            placeholder={'Выберите из списка'}
                            value={mRule?.default_masking_rule_type_id}
                            onChange={onTypeChange}
                            options={ruleTypes?.map((rType) => ({label: rType.name, value: rType.id || ''}))}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            showSearch/>

                        {errors.defaultType ? (
                            <p className={styles.error}> {errors.defaultType} </p>
                        ) : null}
                    </div>


                    <div className={'body_1_long'}
                         style={{marginTop: '10px'}}
                         hidden={mRule?.default_masking_rule_type_id != 'dictionary'}>
                        Словарь
                        <span className={styles.required}>
                            *
                        </span>
                    </div>
                    <div hidden={mRule?.default_masking_rule_type_id != 'dictionary'}>

                        <Select
                            labelInValue
                            style={{width: '100%'}}
                            placeholder={'Выберите из списка'}
                            value={mRule?.default_masking_rule_params?.dictionary_id}
                            onChange={onDefaultDictionaryChange}
                            filterOption={filterOption}
                            options={dictionary?.map((dict) => ({label: dict.name, value: dict.id || ''}))}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            showSearch/>
                        {errors.defaultDictionary ? (
                            <p className={styles.error}> {errors.defaultDictionary} </p>
                        ) : null}
                    </div>


                    <div className={styles.margin_20 + ' body_1_long neutral_90'}
                         hidden={mRule?.default_masking_rule_type_id != 'dictionary'}>

                        <Checkbox checked={mRule?.default_masking_rule_params?.keep_mapping}
                                  name="keep_mapping"
                                  onChange={(e) => onDefaultParamChange(e)}
                        />
                        <span style={{marginLeft: '5px'}}> Keep user-token-value mappings</span>

                    </div>


                    <div className={styles.margin_20 + ' body_1_long neutral_90'}
                         hidden={mRule?.default_masking_rule_type_id != 'default'}>
                        <Checkbox name="keep_original_length"
                                  checked={mRule?.default_masking_rule_params?.keep_original_length}
                                  onChange={(e) => onDefaultParamChange(e)}
                        />
                        <span style={{marginLeft: '5px'}}> Keep original string length </span>
                    </div>

                </div>

                <div className={styles.form_right}>

                    <div style={{marginBottom: '4px'}}>
                        Описание

                    </div>
                    <TextArea style={{height: "108px"}}
                              value={mRule?.description}
                              name="description"
                              spellCheck={false}
                              onChange={(e) => onInputChange(e)}/>
                </div>
            </div>

        </ConfigProvider>
    )
}
