import React, {FC} from 'react';
import {ConfigProvider} from 'antd';

import styles from './TokenizationInfo.module.scss';
import {InfoCircleFilled} from '@ant-design/icons';

export type TokenizeInfoProps = {
    action: string;
    formFields?: string;

};

export const TokenizeInfo: FC<TokenizeInfoProps> = ({
                                                        action,
                                                        formFields
                                                    }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    }
                },
            }}>

            <InfoCircleFilled className={styles.item_icon}/>

            <div className={'body_1_long' + ' ' + styles.info_text}>
                <ul>
                    <li>
                        Введите значения через запятую в кавычках после команды  "{formFields}": [  "Значение 1", "Значение 2".
                    </li>
                    <li>
                        Введите название группы токенизации в кавычках после команды "group": "Название группы"
                    </li>
                    <li>
                        Нажмите на кнопку {action} для получения результата
                    </li>
                </ul>
            </div>

        </ConfigProvider>
    )
}
