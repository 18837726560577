import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';

import {GroupsAll} from './GroupsAll'

export function Groups() {

    const {pathname} = useLocation();

    return (
        <>
            {pathname === '/control/groups'
            || pathname === '/tools/groups' ? (
                <> <GroupsAll /> </>
            ) : (
                <>
                    <Outlet/>
                </>
            )}
        </>

    );
}
