import {fetchWithRefresh} from '../auth';
import {optionsGet, optionsPatch, optionsPost, URL} from '../requst_templates';
import {handleHttpResponse} from '../../utils';
import {RequestFilter, toUrl} from '../../types/RequestFilter/RequestFilter';

export const getDictionaries = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/dictionary`,
    optionsGet(),
).then(handleHttpResponse);

export const getDictionariesPageable = async (limit: number, offset: number) => fetchWithRefresh(
    `${URL}/v1/dictionary?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(offset)}`,
    optionsGet(),
).then(handleHttpResponse);

export const getDictionaryById = async (id: string) => fetchWithRefresh(
    `${URL}/v1/dictionary/${encodeURIComponent(id)}`,
    optionsGet(),
).then(handleHttpResponse);

export const createDictionary = async (data: any) => fetchWithRefresh(`${URL}/v1/dictionary`, optionsPost(data)).then(handleHttpResponse);

export const updateDictionary = async (dictionaryId: string, data: any) => fetchWithRefresh(
    `${URL}/v1/dictionary/${encodeURIComponent(dictionaryId)}`,
    optionsPatch(data),
).then(handleHttpResponse);

export const getDictionariesByFilter = async (filter: RequestFilter) => fetchWithRefresh(
    `${URL}/v1/dictionary${toUrl(filter)}`,
    optionsGet(),
).then(handleHttpResponse);
