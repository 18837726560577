import React, {FC} from 'react';
import {uuid} from '../../utils';

export type CliJsonSingleOutputProps = {
    json?: any;

};

export const CliJsonSingleOutput: FC<CliJsonSingleOutputProps> = ({

                                                                      json
                                                                  }) => {


    return (
        <span className={'body_1_long'} key={uuid()}>
            <table className="body_1_long">
                <tbody>

                    {json ?
                        Object.keys(json).map((key, i) => {
                            return (
                                <tr key={uuid()}>

                                    <td> {key} </td>
                                    <td
                                        style={{paddingLeft: '10px'}}> {json[key as keyof typeof json] } </td>
                                </tr>
                            )
                        })
                        : <>
                        </>
                    }

                </tbody>

            </table>
        </span>
    )
}
