import React from 'react';
import {CloseCircleFilled} from '@ant-design/icons';
import styles from './NoFilterData.module.scss'

export const NoFilterData = () => {
    return (
        <div className={styles.no_filter_data + ' body_1_long neutral_90'}>

            <div>
                <CloseCircleFilled className={styles.item_icon}/>
            </div>
            <div style={{marginTop: '10px'}}>
                Для выбранных значений <br/>результатов не найдено
            </div>
        </div>
    )
}


