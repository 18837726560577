import {fetchWithRefresh} from '../auth';
import {optionsDelete, optionsGet, optionsPatch, optionsPost, URL} from '../requst_templates';
import {handleHttpResponse} from '../../utils';


export const getMaskingRules = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/masking_rule`,
    optionsGet(),
).then(handleHttpResponse);

export const getMaskingRulesPageable = async (limit: number, offset: number) => fetchWithRefresh(
    `${URL}/v1/masking_rule?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(offset)}`,
    optionsGet(),
).then(handleHttpResponse);

export const getMaskingRuleById = async (id: string) => fetchWithRefresh(
    `${URL}/v1/masking_rule/${encodeURIComponent(id)}`,
    optionsGet(),
).then(handleHttpResponse);

export const getMaskingRuleTypes = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/masking_rule/types`,
    optionsGet(),
).then(handleHttpResponse);

export const getMaskingRuleByType = async () => fetchWithRefresh(
    `${URL}/v1/masking_rule/types/default`,
    optionsGet(),
).then(handleHttpResponse);

export const createMaskingRule = async (data: any) => fetchWithRefresh(
    `${URL}/v1/masking_rule/`, optionsPost(data)
).then(handleHttpResponse);

export const updateMaskingRule = async (id: string, data: any) => fetchWithRefresh(
    `${URL}/v1/masking_rule/${encodeURIComponent(id)}`,
    optionsPatch(data),
).then(handleHttpResponse);

export const deleteMaskingRule = async (ruleId: string) => fetchWithRefresh(
    `${URL}/v1/masking_rule/${encodeURIComponent(ruleId)}`,
    optionsDelete(),
).then(handleHttpResponse);
