import React, {FC} from 'react';
import {Empty, List} from 'antd';
import {GroupClassHeader} from './GroupClassHeader';
import {DictionaryItem} from '../../types/DictionaryItem';
import {ClassSelectComponent} from '../../components/ClassSelectComponent/ClassSelectComponent';
import {ClassToDictionary} from '../../types/ClassToDictionary';
import styles from './Groups.module.scss';

export type GroupClassListProps = {
    className?: string;
    hasAdminRole?: boolean;
    dictionaries: DictionaryItem [];
    isValidate: boolean;
    classData: ClassToDictionary[];
};

export const GroupClassList: FC<GroupClassListProps> = ({
                                                            className,
                                                            hasAdminRole,
                                                            classData,
                                                            dictionaries,
                                                            isValidate
                                                        }) => {

    let locale = {
        emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                   description={'Нет данных'}/>
        )
    };

    return (
        <List
            size="small"
            header={<GroupClassHeader/>}
            locale={locale}
            dataSource={classData}
            bordered
            renderItem={(item) => (<List.Item>

                <div style={{display: 'flex', width: '100%'}}>

                    <div style={{width: '45%'}}>
                        <div style={{marginBottom: '4px'}}>
                            {item.code}
                            <span className={styles.required}>
                            *
                        </span>
                        </div>
                    </div>
                    <div style={{width: '5%'}}/>

                    <div style={{width: '45%'}}>
                        <div style={{marginBottom: '4px'}}>
                            <ClassSelectComponent dictionaries={dictionaries}
                                                  hasAdminRole={hasAdminRole}
                                                  val={item.dictionary_id}
                                                  isValidate={isValidate}
                                                  item={item}/>
                        </div>
                    </div>
                </div>
            </List.Item>)}
        />
    )
}
