import {Card, ConfigProvider} from 'antd';
import React, {useEffect, useState} from 'react';
import {MonitoringServer} from './MonitoringServer';
import {getMonitoringState} from '../../services/pages/monitoring';
import {MonitoringNode} from '../../types/Manitoring/MonitoringNode';

export function MonitoringAll() {

    let server_one = {
        ram_value: '72',
        ram_value_desc: 'GB',
        ram_class_name: 'success_20_bg',
        cpu_value: 'N/A',
        cpu_value_desc: '%',
        cpu_class_name: 'neutral_10_bg',
        ram_used_value: '97',
        ram_used_value_desc: 'GB',
        ram_used_class_name: 'error_20_bg'
    }

    const [monitoringNodes, setMonitoringNodes] = useState<MonitoringNode[]>([]);

    useEffect(() => {
        getMonitoringState().then((resp) => {
            console.log(resp);
            setMonitoringNodes(resp['nodes']);
        });
    }, []);

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {

                        Input: {
                            colorPrimary: '#F75623',
                            activeShadow: '0 0 0 1px #F75623',
                            algorithm: true,
                        },
                        Table: {
                            headerBg: '#FFFFFF'
                        }
                    },
                }}>
                <Card bordered={false} style={{width: '100%', height: '100%'}} key={'monitoring'}>
                    <div className={'header_1'}>Мониторинг</div>

                    {monitoringNodes.map((node) =>
                        <MonitoringServer id={node.node_id}
                                          name={node.node_name}
                                          monitoringNode={node}
                                          key={node.node_id}
                                          title={'Сервер ' + node.node_name}
                                          params={server_one}/>
                    )}
                </Card>
            </ConfigProvider>
        </>
    )
}
