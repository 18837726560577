import React, {FC} from 'react';

import styles from './NoData.module.scss'
import {NavButton} from '../NavButton/NavButton';
import {
    BookOutlined,
    CloseCircleFilled,
    GroupOutlined
} from '@ant-design/icons';
import {ActionButton} from '../ActionButton/ActoinButton';

export type NoDataProps = {
    url?: string;
    iconName: string;
    title: string;
    description: string;
    isNavButton?: boolean;
    onActionHandler?: () => void;
    isHideBtn: boolean;

};

export const NoData: FC<NoDataProps> = ({
                                            url,
                                            title,
                                            iconName,
                                            description,
                                            isNavButton,
                                            onActionHandler,
                                            isHideBtn
                                        }) => {

    return (
        <div className={styles.no_data + ' body_1_long neutral_90'}>

            <div>
                <CloseCircleFilled className={styles.item_icon} hidden={iconName != 'CloseCircleFilled'}/>
                <BookOutlined className={styles.item_icon} hidden={iconName != 'BookOutlined'}/>
                <GroupOutlined className={styles.item_icon} hidden={iconName != 'GroupOutlined'}/>
            </div>
            <div className={styles.no_data_div + ' header_2 neutral_90'}>
                {title}
            </div>
            <div className={styles.no_data_div + ' body_1_long neutral_90'}>
                {description}
            </div>

            <div className={styles.no_data_div} hidden={isHideBtn}>
                {isNavButton ?
                    <NavButton route={url||''}
                               btnText={'Добавить'}
                               type={'primary'}
                               className={'button_1'}/> :
                    <ActionButton btnText={'Добавить'}
                                  onClickHandler={onActionHandler}
                                  type={'primary'}
                                  htmlType={'button'} className={'button_1'}/>}
            </div>

        </div>
    )
}


