import React, {FC, useEffect, useState} from 'react';
import {Select} from 'antd';
import {TUser} from '../../types/User';

export type RowSelectComponentProps = {
    className?: string;
    onSelect?: () => void;
    hasAdminRole?: boolean;
    users?: TUser [];
    record?: any;
    val?: any;
};

export const RowSelectComponent: FC<RowSelectComponentProps> = ({

                                                                    className,
                                                                    onSelect,
                                                                    hasAdminRole,
                                                                    users,
                                                                    record,
                                                                    val
                                                                }) => {
    const [value, setValue] = useState();

    useEffect(() => {
        setValue(val);
    }, [val]);


    const onChange = (val: any) => {
        setValue(val);
        record.userId = val;

    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    return (
        <Select style={{width: '100%'}}
                disabled={!hasAdminRole}
                defaultValue={val}
                onChange={(val) => onChange(val)}
                value={value}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                placeholder={'Выберите из списка'}
                filterOption={filterOption}
                options={users?.map((user) => ({label: user.username, value: user.id || ''}))}
                showSearch/>
    );

}
