import {Builder} from './Builder';
import {RequestFilter} from './RequestFilter';
import {Query} from './Query';

export class RequestFilterBuilder implements Builder {

    private filter: RequestFilter;

    constructor() {
        this.filter = new RequestFilter();
    }

    public setLimit(limit: number): this {
        this.filter.setLimit(limit);
        return this;
    }

    public setOffset(offset: number): this {
        this.filter.setOffset(offset);
        return this;
    }

    public setQuery(query: Query): this {
        this.filter.setQuery(query);
        return this;
    }

    public setSortBy(sortBy: string): this {
        this.filter.setSortBy(sortBy);
        return this;
    }

    public setSortOrder(sortOrder: string): this {
        this.filter.setSortOrder(sortOrder);
        return this;
    }

    public build(): RequestFilter {
        return this.filter;
    }


}
