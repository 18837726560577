import React, {FC, useEffect, useRef} from 'react';
import {ConfigProvider} from 'antd';
import styles from './Cli.module.scss'


export type CliCandidatesProps = {
    candidates: string[];
    onVariantSelect: (key: string, variant: string) => void;
};

export const CliCandidates: FC<CliCandidatesProps> = ({

                                                          candidates,
                                                          onVariantSelect
                                                      }) => {

    const ref = useRef<HTMLDivElement | undefined | any>(undefined);

    const keys: string[] = ['Enter', 'Escape'];


    useEffect(() => {
        if (candidates && candidates?.length > 0) {
            if (ref && ref.current != null) {
                ref.current?.focus();
            }
        }

    }, [candidates]);

    const onFocusLast = () => {
        console.log('ON_FOCUS_LAST');
        if (ref && ref.current != null) {
            ref.current?.focus();
        }

    }

    const onKeyDownHandler = (key: string, variant: string) => {
        console.log('On_key_down');
        console.log(key);
        console.log(variant);


        if (keys.includes(key)) {

            onVariantSelect(key, variant);
        }
    }

    return (
        <div className={'body_1_long'}>

                {<button key={candidates[0]}
                         className={styles.btn_v}
                         onKeyDown={(e) => onKeyDownHandler(e.key, candidates[0])}
                         ref={ref}>{candidates[0]}</button>}
                {


                    candidates?.slice(1,candidates.length).map((str, index) => {
                            return (
                                <button key={str}
                                        onKeyDown={(e) => onKeyDownHandler(e.key, str)}
                                        className={styles.btn_v}
                                        tabIndex={0}>{str}</button>
                            )
                        }
                    )
                }

                {<input maxLength={10} style={{width: '100px'}} onFocus={onFocusLast}/>}

        </div>
    )
}
