import {CliOutputType} from './CliOutputType';

export class CliOutput {

    type: CliOutputType;
    output: any;
    is_hide_header?:boolean;

    constructor(type: CliOutputType, output: any) {
        this.type = type;
        this.output = output;
    }
}
