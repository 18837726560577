import React, {FC, useEffect, useState} from 'react';

import {PlusCircleFilled} from '@ant-design/icons';
import styles from './MaskingRules.module.scss';
import {ConfigProvider, Table} from 'antd';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {MaskingRuleSchemaModal} from './MaskingRuleSchemaModal';
import {MRuleType} from '../../types/MaskingRule/MRuleType';
import {DictionaryItem} from '../../types/DictionaryItem';
import {MRuleTypeSchema} from '../../types/MaskingRule/MRuleTypeSchema';
import {RowDeleteComponent} from '../../components/RowDeleteComponent/RowDeleteComponent';
import {MRule} from '../../types/MaskingRule/MRule';
import {NoData} from '../../components/NoData/NoData';
import {MaskingRuleSchemaParam} from './MaskingRuleSchemaParam';


export type MaskingRuleSchemaProps = {

    className?: string;
    ruleTypes?: MRuleType[];
    dictionary?: DictionaryItem[];
    mRule?: MRule;
    isValidate: boolean;
    onSetSchemas: (schemas: MRuleTypeSchema[]) => void;

};
export const MaskingRuleSchema: FC<MaskingRuleSchemaProps> = ({
                                                                  className,
                                                                  ruleTypes,
                                                                  dictionary,
                                                                  mRule,
                                                                  isValidate,
                                                                  onSetSchemas
                                                              }) => {

    let locale = {
        emptyText: (
            <NoData title={'Нет схем маскирования'}
                    isHideBtn={true}
                    iconName={'GroupOutlined'}
                    description={'Схемы маскирования не добавлены'}/>
        )
    };

    const [isAddSchemaHidden, setIsAddSchemaHidden] = useState<boolean>(!!mRule?.masking_schemas);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [hoverRowId, setHoverRowId] = useState<string>('');
    const [hoverSchemaId, setHoverSchemaId] = useState<string>('');


    const [data, setData] = useState<MRuleTypeSchema[]>(mRule?.masking_schemas ? mRule?.masking_schemas : []);
    const [editSchema, setEditSchema] = useState<MRuleTypeSchema | null>(null);

    const addRuleSchema = () => {
        setIsModalOpen(true);
        setIsAddSchemaHidden(true);
    }

    const onCancelModal = () => {
        setIsModalOpen(false);
    }

    const onConfirmHandler = (mRuleTypeSchema: MRuleTypeSchema) => {
        setIsModalOpen(false);
        console.log(mRuleTypeSchema);

        if(!mRuleTypeSchema?.id) {
            setData((pre) => {
                return [...pre, mRuleTypeSchema]
            });
        } else {
            deleteHandler(mRuleTypeSchema);
            setData((pre) => {
                return [...pre, mRuleTypeSchema]
            });
        }
    }

    useEffect(() => {
        onSetSchemas(data);

    }, [isValidate]);

    const deleteHandler = (record: MRuleTypeSchema) => {
        let removed;
        if (record?.rowId) {
            removed = data.filter(schema => schema.rowId != record.rowId);
        } else {
            removed = data.filter(schema => schema.id != record.id);
        }

        setData([...removed]);
    }

    const columns = [

        {
            key: '2',
            title: 'Объект',
            render: (record: MRuleTypeSchema) => {
                return <>{record.entity_name}</>
            }
        },
        {
            key: '3',
            title: 'Тип',
            render: (record: MRuleTypeSchema) => {
                return <>{record.mRuleTypeName}</>
            }
        },
        {
            key: '4',
            title: 'Параметры',
            render: (record: MRuleTypeSchema) => {
                return <MaskingRuleSchemaParam schema={record}/>
            }
        },
        {
            key: '5',
            width: 100,
            render: (record: MRuleTypeSchema) => {
                return <RowDeleteComponent hidden={!(hoverRowId === record.rowId || hoverSchemaId === record.id)}
                                           title={'Удалить схему маскирования'}
                                           onClickHandler={() => deleteHandler(record)}/>
            }
        }
    ]

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorBorder: 'none',
                        colorPrimaryHover: '#F75623',
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623'
                    },
                    Table: {
                        headerBg: '#FFFFFF'
                    }
                },
            }}>
            <div className={'body_1_long'} hidden={isAddSchemaHidden}>
                <PlusCircleFilled className={styles.schema_item_icon}/>

                <div style={{width: '434px'}} className={'body_1_long neutral_90'}>
                    Для работы с правилом маскирования необходимо добавить схему маскирования.
                </div>


            </div>

            <div hidden={!isAddSchemaHidden}>
                <Table
                    onRow={(record: MRuleTypeSchema) => {
                        return {
                            onMouseEnter: () => {
                                setHoverRowId(record?.rowId || '');
                                setHoverSchemaId(record?.id || '');

                                console.log('ON_MOUSE_ENTER');
                                console.log(hoverRowId);
                                console.log(hoverSchemaId);
                            },
                            onMouseLeave: () => {
                                setHoverRowId('');
                                setHoverSchemaId('');
                            },
                        };
                    }}
                    locale={locale}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    size="small"/>

            </div>

            <div style={{marginTop: '25px'}}>
                <ActionButton type={'default'}
                              htmlType={'button'}
                              className={'button_1'}
                              onClickHandler={addRuleSchema}
                              btnText={'Добавить схему'}/>
            </div>

            <MaskingRuleSchemaModal isModalOpen={isModalOpen}
                                    editSchema={editSchema}
                                    ruleTypes={ruleTypes}
                                    dictionary={dictionary}
                                    onConfirmHandler={onConfirmHandler}
                                    onCancelHandler={onCancelModal}
                                    title={'Добавление схемы маскирования'}/>

        </ConfigProvider>
    );
};
