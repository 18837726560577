import {fetchWithRefresh} from '../auth';
import {optionsGet, URL} from '../requst_templates';
import {handleHttpResponse} from '../../utils';

export const getMonitoringState = async () => fetchWithRefresh(
    `${URL}/v1/monitor/state`,
    optionsGet(),
).then(handleHttpResponse);

export const getMonitoringMetrics = async (id: string, type: string, scale: string, n: number) => fetchWithRefresh(
    `${URL}/v1/monitor/metrics/${encodeURIComponent(id)}?type=${encodeURIComponent(type)}&scale=${encodeURIComponent(scale)}&n=${encodeURIComponent(n)}`,
    optionsGet(),
).then(handleHttpResponse);
