import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {MaskingRulesAll} from './MaskingRulesAll';


export function MaskingRules() {

    const {pathname} = useLocation();

    return (
        <>
            {pathname === '/control/rules' ? (
                <> <MaskingRulesAll/> </>
            ) : (
                <>
                    <Outlet/>
                </>
            )}
        </>

    );
}
