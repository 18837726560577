import styles from './Templates.module.scss';
import React, {useEffect, useState} from "react";
import {getTemplates} from '../../services/pages/templates';
import {Template} from '../../types/Template';
import {Card, ConfigProvider, Table} from 'antd';
import {TableFilterItem} from '../../types/TableFilterItem';
import {FilterItem} from '../../types/FilterItem';
import {TableDropdownFilter} from '../../components/TableDropdownFilter/TableDropdownFilter';
import {SearchInput} from '../../components/SearchInput/SearchInput';
import {map} from 'lodash';
import {SearchState} from '../../types/SearchState';
import {NoFilterData} from '../../components/NoFilterData/NoFilterData';


export function Templates() {

    const [templates, setTemplates] = useState<Template[]>([]);

    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);

    const [nameFilter, setNameFilter] = useState<TableFilterItem[]>( []);
    const [nameFilterItems, setNameFilterItems] = useState<FilterItem[]>( []);
    const [nameFiltered, setNameFiltered] = useState<string[]| null>( []);

    const [searchValue, setSearchValue] = useState<string>('');

    const [searchState, setSearchState] = useState<SearchState>({
        filteredInfo: null,
        sortedInfo: null,
        filteredData: [],
        filtered: false,
        searchText: ""
    });

    const locale = {
        emptyText: (
            <NoFilterData/>
        )
    };


    useEffect(() => {
        getTemplatesData();
    }, []);

    useEffect(()=> {
        initFilter();
    }, [templates]);


    const getTemplatesData = () => {
        getTemplates().then((response) => {
            console.log(response);
                setTemplates(response);
            }
        ).catch((err) => console.log(err))
    }

    const initFilter=() => {
        let names = templates?.map((tmp)=> tmp.name);
        names = names?.filter((value, index) => names?.indexOf(value) === index);

        const nameFilter = names?.map((val) => ({text: val, value: val}));
        const nameFilterItem = names?.map((val) => ({label: val, value: val}));

        setNameFilterItems([...nameFilterItem]);
        setNameFilter([...nameFilter]);
    }

    const applyNameFilter = (filteredValues: string[]) => {
        setNameFiltered([...filteredValues]);
    }

    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchState(event.target.value);

    }

    const clearSearchInput = () => {
        updateSearchState('');
    }


    const updateSearchState = (searchText:string ) => {
        setSearchValue(searchText);
        Object.assign(searchState, {searchText: searchText});


        const filtered = map(templates, record => {
            const nameMatch = record.name.includes(searchText);
            const templateMatch = record.description?.includes(searchText);

            if(!nameMatch && !templateMatch) {
                return null;
            }
            return record;
        }).filter(record => !! record);

        Object.assign(searchState, {filteredData: searchState.searchText? filtered : templates});
    }


    const columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'template_name',
            width: 240,
            filterDropdown: <TableDropdownFilter
                popupId={'scrollableDiv'}
                onApply={applyNameFilter}
                items={nameFilterItems}/>,
            filters: nameFilter,
            filteredValue: nameFiltered,
            onFilter: (value:any, record:any) => record.name.includes(value),
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'template_description',
            width: 392
        },
        {
            title: 'Шаблон',
            dataIndex: 'regex',
            key: 'template_regex',
            width: 260,

        }

    ]

    return (
        <ConfigProvider
            theme={{
                components: {

                    Input: {
                        colorPrimary: '#F75623',
                        activeShadow: '0 0 0 1px #F75623',
                        algorithm: true,
                    },
                    Table: {
                        headerBg: '#FFFFFF',
                        colorPrimary:'#F75623',
                        algorithm: true
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorErrorBorder:'#D92020',
                        algorithm: true,
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        algorithm: true,
                    },
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%'}} key={'templatesAll'}>
                <div className={styles.page}>
                    <div className={'header_1'}>
                        Шаблоны токенизации
                        <SearchInput searchValue={searchValue}
                                     onChangeHandler={ handleSearchInput}
                                     onClearSearchInput={ clearSearchInput }/>
                    </div>
                    <div className={'header_2'}>
                        { templates?.length + ' из ' + templates?.length }
                    </div>
                    <div className={styles.form}>
                        <div id="scrollableDiv" className={'scrollable_div'}>

                            <Table
                                key={'templates_table'}
                                id="templates_table"
                                rowKey={'name'}
                                dataSource={searchState.searchText ? searchState.filteredData : templates}
                                getPopupContainer={()=>document.getElementById('templates_table')!}
                                columns={columns}
                                locale={locale}
                                pagination={false}
                                size="small"/>
                        </div>
                    </div>
                </div>
            </Card>
        </ConfigProvider>
    );
}
