import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { auth } from './reducers/auth';
import { tabs } from './reducers/tabs';
import { configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  auth: auth.reducer,
  tabs: tabs.reducer,
});

export const store = configureStore({
  reducer: rootReducer
})
//export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
