import {Outlet} from "react-router";
import React from "react";


export function Control() {

    return (
        <div>
            <Outlet />
        </div>

    );
}
