import styles from '../Groups/Groups.module.scss';

import * as React from 'react';
import {useEffect, useState} from 'react';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {Card, Collapse, CollapseProps, ConfigProvider} from 'antd';
import {CollapseLabel} from '../../components/CollapseLabel/CollapseLabel';
import {CommonRuleInfo} from './CommonRuleInfo';
import {MaskingRuleSchema} from './MaskingRuleSchema';
import {MRuleType} from '../../types/MaskingRule/MRuleType';
import {createMaskingRule, deleteMaskingRule, getMaskingRuleById, getMaskingRuleTypes, updateMaskingRule} from '../../services/pages/masking_rule';
import {DictionaryItem} from '../../types/DictionaryItem';
import {getDictionariesByFilter} from '../../services/pages/dictionary';
import {MRule} from '../../types/MaskingRule/MRule';
import {MRuleTypeSchema} from '../../types/MaskingRule/MRuleTypeSchema';
import {useParams} from 'react-router';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';
import {doNavigate, i18n} from '../../utils';
import {LabelInValue} from '../../types/LabelInValue';
import {DeleteButton} from '../../components/DeleteButton/DeleteButton';
import {DeleteModal} from '../../components/DeleteModal/DeleteModal';
import {useNavigate} from 'react-router-dom';
import {RequestFilterBuilder} from '../../types/RequestFilter/RequestFilterBuilder';


export function MaskingRule() {

    const {id} = useParams();
    const navigate = useNavigate();

    const [isValidate, setIsValidate] = useState<boolean>(false);
    const [ruleTypes, setRuleTypes] = useState<MRuleType[] | undefined>(undefined);
    const [dictionaries, setDictionariesData] = useState<DictionaryItem[] | undefined>([]);
    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [mRule, setMRule] = useState<MRule>({
        id: '',
        name: '',
        description: '',
        masking_schemas: [],
        default_masking_rule_type_id: ''
    });

    const [validateResults, setValidateResults] = useState({
        hasCommonError: true
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setMRule({
            ...mRule, [e.target.name]: value,
        });
    }

    const handleDefaultRuleTypeChange = (val: any) => {
        let selected = val as LabelInValue;
        setMRule((prev) => ({...prev, default_masking_rule_type_id: selected.value}));
        setMRule((prev) => ({...prev, default_masking_rule_params: {}}));
        if (selected.value === 'default') {
            setMRule((prev) => ({
                ...prev,
                default_masking_rule_params: {...prev.default_masking_rule_params, keep_original_length: false}
            }));
        }
        if (selected.value === 'dictionary') {
            setMRule((prev) => ({
                ...prev,
                default_masking_rule_params: {...prev.default_masking_rule_params, keep_mapping: false}
            }));
        }
    };

    const handleDefaultParamChange = (e: any) => {
        setMRule((prev) => ({
            ...prev,
            default_masking_rule_params: {...prev.default_masking_rule_params, [e.target.name]: e.target.checked}
        }));
    };

    const handleDefaultDictChange = (val: any) => {
        let selected = val as LabelInValue;
        setMRule((prev) => ({
            ...prev,
            default_masking_rule_params: {...prev.default_masking_rule_params, dictionary_id: selected.value}
        }));
    };

    useEffect(() => {
        getMaskingRulesTypeData();
        getDictionariesData();
        getMaskingRuleData();
    }, [])

    const getMaskingRulesTypeData = () => {
        getMaskingRuleTypes().then((resp) => {
            setRuleTypes([...resp]);
        });
    }

    const getDictionariesData = () => {
        let filter = new RequestFilterBuilder()
            .setLimit(1000)
            .setOffset(0)
            .setSortBy('name')
            .setSortOrder('asc')
            .build();

        getDictionariesByFilter(filter).then((resp)=> {
            setDictionariesData(resp['items']);
        });
    }

    const getMaskingRuleData = () => {
        if (id) {
            getMaskingRuleById(id || '').then((response) => {
                setMRule(response);
                }
            ).catch((err) => console.log(err));
        }
    }

    const createRule = () => {
        setIsValidate(true);
    }

    const onCommonValidateHandler = (hasError: boolean) => {
        setIsValidate(false);
        setValidateResults((pre) => {
            return {...pre, hasCommonError: hasError}
        });
    }

    const onAddSchemasHandler = (schemas: MRuleTypeSchema[]) => {
        Object.assign(mRule, {masking_schemas: schemas})

    }

    useEffect(() => {

        if (!validateResults.hasCommonError) {
            finishSubmit();
        }
    }, [validateResults]);

    const finishSubmit = () => {
        saveMaskingRule()
    }

    const saveMaskingRule = () => {
        saveRule(mRule);
    }

    const saveRule = (mRule: MRule) => {
        console.log(mRule);
        if (id) {
            delete mRule.id;
            updateMaskingRule(id || '', mRule).then((res) => {
                handleResponse(res, 'update');
            }).catch((error) => {
                setAntAlertProps({
                    severity: 'error',
                    content: i18n('update_rule_error') + error,
                    show: true
                });
            });
        } else {
            createMaskingRule(mRule)
                .then((res) => {
                    doNavigate(`/control/rules`, navigate);
                    handleResponse(res, 'create');
                }).catch((error) => {
                setAntAlertProps({
                    severity: 'error',
                    content: i18n('create_rule_error') + error,
                    show: true
                })
            });
        }
    }

    const onDeleteRule = (rule: MRule) => {

        deleteMaskingRule(rule.id || '').then((res) => {
            console.log(res);
            if (res.errors != undefined && res.errors.length > 0) {
                setAntAlertProps({severity: 'error',
                    content: i18n('delete_rule_error') + res.errors[0].message,
                    show: true});

            } else {
                doNavigate(`/control/rules`, navigate);
            }

        }).catch((error) => {
            setAntAlertProps({
                severity: 'error',
                content: i18n('delete_rule_error') + error,
                show: true
            })
        });
    }

    const handleResponse = (res: any, action: string) => {

        if (res.errors != undefined && res.errors.length > 0) {
            setAntAlertProps({
                severity: 'error',
                content: i18n(action + '_rule_error') + mRule.name + ' ' + res.errors[0].message,
                show: true
            });
        } else {
            setAntAlertProps({
                severity: 'success',
                content: i18n(action + '_rule_success') + mRule.name + ' ',
                show: true
            });
        }
    }

    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }

    const onDeleteButtonClick = () => {
        setIsDeleteModalOpen(true);
    }

    const onDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    }

    const onDeleteConfirm = () => {
        setIsDeleteModalOpen(false);
        onDeleteRule(mRule);
    }

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <CollapseLabel text={'Общая информация'}/>,
            children: <CommonRuleInfo isValidate={isValidate}
                                      mRule={mRule}
                                      ruleTypes={ruleTypes||[]}
                                      dictionary={dictionaries||[]}
                                      onValidateFinishHandler={onCommonValidateHandler}
                                      onInputChange={handleChange}
                                      onDefaultParamChange={handleDefaultParamChange}
                                      onDefaultDictionaryChange={handleDefaultDictChange}
                                      onTypeChange={handleDefaultRuleTypeChange}/>

        },
        {
            key: '2',
            label: <CollapseLabel text={'Схемы маскирования'}/>,
            children: <MaskingRuleSchema ruleTypes={ruleTypes}
                                         isValidate={isValidate}
                                         onSetSchemas={onAddSchemasHandler}
                                         mRule={mRule}
                                         dictionary={dictionaries}/>

        }
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Collapse: {
                        borderRadiusLG: 0,

                    }
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%', overflowY:'auto'}} id="maskingRule">
                <div id="group_page" className={styles.page}>

                    <div className="header_1">
                        {id ? 'Редактирование правила маскирования' : 'Добавление правила маскирования'}

                        <div className={styles.float_right} >

                            <span style={{marginRight: '10px'}}>
                                <DeleteButton onClickHandler={onDeleteButtonClick} disabled={!id}/>
                            </span>
                            <ActionButton btnText={'Сохранить'}
                                          htmlType={'submit'}
                                          type={'primary'}
                                          onClickHandler={createRule}
                            />
                        </div>
                    </div>

                    <Collapse items={items}
                              defaultActiveKey={['1']}
                              bordered={false}
                              ghost={true}/>

                    <div className={styles.form}>

                    </div>

                    <div className={styles.notification}>

                        <AntAlert severity={antAlertProps.severity}
                                  content={antAlertProps.content}
                                  onClose={() => alertClose()}
                                  show={antAlertProps.show}/>
                    </div>
                </div>

                <DeleteModal title={'Удаление правила маскирования'}
                             isModalOpen={isDeleteModalOpen}
                             popupContainer={'group_page'}
                             content={'Подтвердить удаление правила маскирования ' + mRule?.name}
                             onConfirmHandler={()=>onDeleteConfirm()}
                             onCancelHandler={()=>onDeleteCancel()}/>
            </Card>
        </ConfigProvider>
    );
}
