import styles from './TableDropdownFilter.module.scss'
import React, {FC, useState} from 'react';
import {Checkbox, Select} from 'antd';
import {ActionButton} from '../ActionButton/ActoinButton';
import {FilterItem} from '../../types/FilterItem';


export type TableDropdownFilterProps = {
    onApply: (filtered: string[]) => void;
    items: FilterItem[] | undefined;
    popupId: string;
};

export const TableDropdownFilter: FC<TableDropdownFilterProps> = ({
                                                                      onApply,
                                                                      items,
                                                                      popupId
                                                                  }) => {

    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const applyFilter = () => {
        onApply(selectedValues);
    }

    const clearFilter = () => {
        setSelectedValues([]);
    }

    const onSelectChange = (value: string[]) => {
        setSelectedValues([...value]);
    }

    return (
        <>
            <div className={styles.filter_div}>


                <div className={styles.select_div}>


                    <Select style={{width: '100%'}}
                            mode={'tags'}
                            value={selectedValues}
                            getPopupContainer={() => document.getElementById(popupId)!}
                            placeholder={'Выберите значение'}
                            onChange={(val) => onSelectChange(val)}
                            menuItemSelectedIcon={''}
                            options={items}
                            optionRender={(opt) => {
                                return <Checkbox checked={selectedValues.includes(opt.data.value)}> {opt.data.label} </Checkbox>
                            }}
                    />
                </div>

                <div className={styles.buttons_div}>

                    <ActionButton btnText={'Применить'}
                                  htmlType={'submit'}
                                  onClickHandler={applyFilter}
                                  type={'primary'}

                    />
                    <span style={{marginLeft: '10px'}}>
                    <ActionButton type={'default'}
                                  htmlType={'button'}
                                  className={'button_1'}
                                  onClickHandler={clearFilter}
                                  btnText={'Очистить фильтр'}/>
                    </span>
                </div>

            </div>
        </>
    )
}
