import React, {FC} from 'react';


export type CollapseLabelProps = {
    text?: string;
    className?: string;
};

export const CollapseLabel: FC<CollapseLabelProps> = ({
                                                          text,
                                                          className,
                                                      }) => {


    return (


        <div className={'header_2'}> {text}</div>

    )
}
