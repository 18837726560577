import {Exception} from 'sass';
import {NavigateFunction} from 'react-router';
import ru from './lang/ru.json';
import en from './lang/en.json';
import {CurrentUser} from './types/CurrentUser';

export const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export function i18n(phrase: string) {
  const language = 'ru'; // navigator.languages[0]
  const languages = {
    ru,
    en,
  };
  const keys = languages[language];
  const translate = keys[phrase as keyof typeof keys];
  return translate ?? phrase;
}

export const loadWord = i18n('Loading...');

export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(
  name: string,
  value: string | null,
  props?: {
    [key: string]: Date | string | number | boolean;
  },
) {
  props = props || {};
  if (typeof props.path === 'undefined') { props.path = '/'; }
  let exp = props.expires;
  if (exp !== undefined && typeof exp === 'number') {
    const d = new Date();
    d.setTime(d.getTime() + exp * 1000);
    exp = props.expires = d;
  }
  if (exp !== undefined && typeof exp === 'object') {
    props.expires = exp.toUTCString();
  }
  if (typeof value === 'string') {
    value = encodeURIComponent(value);
  }
  let updatedCookie = `${name}=${value}`;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const propName in props) {
    updatedCookie += `; ${propName}`;
    const propValue = props[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }
  document.cookie = updatedCookie;
}

export function deleteCookie(name: string) {
  setCookie(name, null, { expires: -1 });
}

export function uuid() {
  return Number(String(Math.random()) + Date.now())
    .toString(32)
    .slice(2);
}

export function parseGetParams(params: { [key: string]: string | number }) {
  return `?${Object.entries(params)
    .map((entry) => `${entry[0]}=${encodeURIComponent(entry[1])}`)
    .join('&')}`;
}
export function handleHttpError(err: Exception) {
  if (err.name != 'AbortError') // cancelled ajax request
  { (window as any).notices.addNotice('error', err.message); }
}

export function handleHttpResponse(resp: Response, asText?: boolean) {
  switch (resp.status) {
    case 200:
      if (asText === undefined) {
        resp.clone().json().then((json) => {
          if (json.errors !== undefined && json.errors.length > 0) {
            json.errors.forEach((element: any) => {
              (window as any).notices.addNotice('error', element.message);
            });
          }
        });
      }

      if (asText !== undefined) { return resp.text(); }
      return resp.json();
    case 400: case 404: case 500: case 409:
      resp.clone().json().then((json) => {
        if (json.errors !== undefined && json.errors.length > 0) {
          json.errors.forEach((element: any) => {
            //(window as any).notices.addNotice('error', element.message);
          });
        } else {
          throw Error(`Ошибка ${resp.statusText}`);
        }
      });
      return resp.json();
    default:
      throw Error(`Ошибка ${resp.statusText}`);
  }
}

export function getArtifactUrl(artifactId: string, artifactType: string) {
  switch (artifactType) {
    case 'entity':
      return `/logic-objects/edit/${artifactId}`;
    case 'entity_query':
      return `/queries/edit/${artifactId}`;
    case 'entity_sample':
      return `/samples/edit/${artifactId}`;
    case 'business_entity':
      return `/business-entities/edit/${artifactId}`;
    case 'dq_rule':
      return `/dq_rule/edit/${artifactId}`;
    default:
      return `/${artifactType}s/edit/${artifactId}`;
  }
}

export const getArtifactTypeDisplayName = (artifact_type: string) => {
  switch (artifact_type) {
    case 'users':
      return i18n('Пользователи');
    default:
      return artifact_type;
  }
};


export const updateArtifactsCount = () => {
  const event = new CustomEvent('countUpdateNeeded');
  document.dispatchEvent(event);
};

export const setDataModified = (v: boolean) => {
  if (window.location.href.indexOf('/settings/') != -1) { (window as any).lbDataModified = false; } else { (window as any).lbDataModified = v; }
};

export const getDataModified = () => (window as any).lbDataModified ?? false;

export const doNavigate = (target: string, nav: NavigateFunction) => {
  if (!getDataModified() || window.confirm('Изменения не сохранены. Продолжить?')) {
    setDataModified(false);
    nav(target);
  }
};

export const getTablePageSize = () => {
  const v = getCookie('table-page-size');
  return v ? parseInt(v) : 50;
};

export const setTablePageSize = (v: number) => {
  setCookie('table-page-size', v.toString());
};

export const loadEditPageData = (id: string, versionId: string, setData: (data: any) => void, setTags: (tags: any) => void,
  setLoading: (v: boolean) => void, setLoaded: (v: boolean) => void,
  getVersion: (id: string, versionId: string) => Promise<any>, loadData: (id: string) => Promise<any>,
  setRatingData: (v: any) => void, setOwnRating: (v: any) => void, getVersions: (id: string) => Promise<any>,
  setVersions: (v: any) => void, setReadOnly: (v: boolean) => void, complete: () => void = () => { }
) => {
  const handleData = (json: any) => {
    setData(json);
    setDataModified(false);
    if (document.getElementById(`crumb_${id}`) !== null) {
      document.getElementById(`crumb_${id}`)!.innerText = json.entity.name;
    }

  };

  if (versionId) {
    getVersion(id, versionId).then(handleData).catch(handleHttpError);
  } else {
    loadData(id).then(handleData).catch(handleHttpError);
  }

  if (complete)
    complete();
};

export const setBreadcrumbEntityName = (id: string, name: string) => {
  const el = document.getElementById(`crumb_${id}`);
  if (el !== null) {
    el.innerText = name;
  }
};


export const getCurrentUserId = () => {
  return getCurrentUser()?.uid
}

export const getCurrentUserSub = () => {
  return getCurrentUser()?.sub
}

export const hasCurrentUserAdminRole = (): boolean => {
  return getCurrentUser()?.roles.indexOf('admin') !== -1;
}

export const getCurrentUser = (): CurrentUser | undefined => {
  let token = getCookie('token');

  let user;
  if (typeof token === "string") {
    let userPart = token.split(".")[1];
    let encodedUserPart = window.atob(userPart);
    user = JSON.parse(encodedUserPart) as CurrentUser;
  }
  return user;
}

export const signOut =() => {
  deleteCookie('login');
  deleteCookie('token');
}

