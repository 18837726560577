import React, {FC, useEffect, useState} from 'react';

import {doNavigate} from '../../utils';
import {useNavigate} from 'react-router-dom';
import {Button, ButtonProps, ConfigProvider} from 'antd';


export type NavButtonProps = {
    route: string;
    className?: string;
    btnText?: string;
    type?: string;
};

export const NavButton: FC<NavButtonProps> = ({
                                                  route,
                                                  className,
                                                  btnText,
                                                  type
                                              }) => {


    const [btnType, setBtnType] = useState<ButtonProps['type']>('default');

    useEffect(() => {
        switch (type) {
            case 'primary':
                setBtnType('primary');
                break;
            case 'link':
                setBtnType('link');
                break;
            case 'text':
                setBtnType('text');
                break;
            case 'dashed':
                setBtnType('dashed');
                break;
            default:
                setBtnType('default');
                break
        }
    });

    const navigate = useNavigate();

    const goCreate = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        doNavigate(route, navigate);
    }
    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    }
                },
            }}>

            <Button
                type={btnType}
                onClick={(event) => goCreate(event)}
                className={className}>
                {btnText}
            </Button>
        </ConfigProvider>
    )
}
