import React, {FC, useEffect, useState} from 'react';
import {ConfigProvider, Progress} from 'antd';
import {DictionaryItem} from '../../types/DictionaryItem';
import {getDictionaryById} from '../../services/pages/dictionary';
import {i18n} from '../../utils';

export type DictionaryStateProps = {


    type?: string;
    btnText?: string;
    className?: string;
    hidden?: boolean;
    dictionary: DictionaryItem;

};

export const DictionaryState: FC<DictionaryStateProps> = ({
                                                              dictionary
                                                          }) => {

    const [progress, setProgress] = useState(0);
    const [state, setState] = useState<string>();

    useEffect(() => {
        setState(dictionary.state);
        let percent = getProgress(dictionary);
        checkProgress(percent);

    }, []);


    const checkProgress = async (percent: number) => {

        let currentProgress = percent;
        while (currentProgress < 100) {
            getDictionaryById(dictionary.id).then((dict) => {
                currentProgress = dict.state

                currentProgress = getProgress(dict);
            });
            await sleep(5000).then();
        }
    }

    function sleep(time: number | undefined) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }


    const getProgress = (dictionary: DictionaryItem): number => {
        let progress = dictionary.progress;

        if (progress?.includes('%')) {
            progress = progress?.substring(0, progress.length - 1);
        }
        let percent = progress === null || progress === undefined ? 100 : Number(progress);

        setProgress(percent);
        setState(dictionary.state);
        return percent;
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    },
                    Progress: {
                        defaultColor: '#F75623',
                        colorSuccess: '#F75623'
                    }
                },
            }}>

            <div style={{display: "flex"}}>
                <div>
                    <Progress style={{float: 'left', paddingRight: '10px'}}
                              type={'circle'}
                              size={24}
                              strokeColor={'#F75623'}
                              percent={progress}/>
                </div>
                <div style={{alignContent: "center"}}>
                    {i18n(state || '')}
                </div>
            </div>

        </ConfigProvider>
    )
}
