import {fetchWithRefresh} from '../auth';
import {
    optionsGet,
    URL,
} from '../requst_templates';
import {handleHttpResponse} from '../../utils';


export const getTemplates = async (body: object | null = null) => fetchWithRefresh(
    `${URL}/v1/token_template`,
    optionsGet(),
).then(handleHttpResponse);

export const getTemplate = async (templateId: string) => fetchWithRefresh(
    `${URL}/v1/token_template/${encodeURIComponent(templateId)}`,
    optionsGet(),
).then(handleHttpResponse);









