import React, {FC, useEffect, useState} from 'react';
import {Select} from 'antd';
import {DictionaryItem} from '../../types/DictionaryItem';
import {ClassToDictionary} from '../../types/ClassToDictionary';
import styles from '../../pages/Groups/Groups.module.scss';

export type ClassSelectComponentProps = {
    className?: string;
    hasAdminRole?: boolean;
    dictionaries?: DictionaryItem [];
    item: ClassToDictionary;
    val?: any;
    isValidate: boolean;


};

export const ClassSelectComponent: FC<ClassSelectComponentProps> = ({
                                                                        className,
                                                                        hasAdminRole,
                                                                        dictionaries,
                                                                        item,
                                                                        val,
                                                                        isValidate
                                                                    }) => {
    const [value, setValue] = useState();

    const [errors] = useState({
        is_class_missing: "",
    });

    useEffect(() => {
        setValue(val);
    }, [val]);

    useEffect(() => {
        if (isValidate) {
            if (!value) {
                Object.assign(errors, {is_class_missing: true})
            }
        }
    }, [isValidate]);

    const onChange = (val: any) => {
        setValue(val);
        item['dictionary_id'] = val;

        if (val) {
            Object.assign(errors, {is_class_missing: false})
        }
    }

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (<>
            <Select style={{width: '100%'}}
                    disabled={!hasAdminRole}
                    defaultValue={val}
                    onChange={(val) => onChange(val)}
                    value={value}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    placeholder={'Выберите из списка'}
                    filterOption={filterOption}
                    options={dictionaries?.map((dictionary) => ({label: dictionary.name, value: dictionary.id || ''}))}
                    showSearch/>
            <span>{errors.is_class_missing ? (
                <p className={styles.error}> {"Необходимо выбрать значение"} </p>
            ) : null}</span></>

    );

}
