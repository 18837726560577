import React, {FC} from 'react';

export const GroupClassHeader: FC<any> = () => {
    return (
        <div style={{display: 'flex'}}>
            <div style={{width: '45%'}}>
                <div style={{marginBottom: '4px'}}>
                    Класс данных
                </div>
            </div>
            <div style={{width: '5%'}}/>
            <div style={{width: '45%'}}>
                <div style={{marginBottom: '4px'}}>
                    Словарь
                </div>
            </div>
        </div>
    )
}
