import React, {FC} from 'react';
import {uuid} from '../../utils';

export type CliLinesOutputProps = {
    lines?: string[];
};

export const CliLinesOutput: FC<CliLinesOutputProps> = ({

                                                            lines
                                                        }) => {
    return (
        <span className={'body_1_long'}>
            {
                lines?.map((str) => {
                        return (<span key={uuid()} className={'body_1_long'}>{str}<br/></span>)
                    }
                )
            }
        </span>
    )
}
