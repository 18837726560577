import {ColorPicker, ConfigProvider} from 'antd';
import {FC, useState} from 'react';
import styles from './Monitoring.module.scss';
import {Color} from 'antd/es/color-picker';

export type MonitoringChartSettingsPopupProps = {


    isOpen: boolean;
    className?: string;
    currentLineColor: string;
    containerId: string;
    onCancelHandler: () => void;
    onApplySettings: (color: string) => void;


};

export const MonitoringChartSettingsPopup: FC<MonitoringChartSettingsPopupProps> = ({
                                                                                        isOpen,
                                                                                        className,
                                                                                        currentLineColor,
                                                                                        containerId,
                                                                                        onCancelHandler,
                                                                                        onApplySettings
                                                                                    }) => {

    const [currentColor, setCurrentColor] = useState<string>(currentLineColor);

    const onColorChange = (color: Color, hex: string) => {
        setCurrentColor(hex);
    }

    const onClose = () => {
        console.log("ON_CLOSE");
        setCurrentColor(currentLineColor);
        onCancelHandler();
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {

                        Input: {
                            colorPrimary: '#F75623',
                            activeShadow: '0 0 0 1px #F75623',
                            algorithm: true,
                        },
                        Table: {
                            headerBg: '#FFFFFF'
                        },
                        Card: {
                            borderRadius: 6
                        }
                    },
                }}>

                <div style={{width: '170px', height: '100px'}}>
                    <div style={{marginBottom: "5px"}}>
                        Цвет линии
                    </div>
                    <ColorPicker
                        size="small"
                        showText
                        disabledAlpha
                        onChange={(color, hex) => onColorChange(color, hex)}
                        getPopupContainer={() => document.getElementById(containerId)!}
                        value={currentColor}/>

                    <div style={{marginTop: "15px"}}>
                        <span className={styles.setting_popup_btn + ' primary_60 body_1_long'}
                              onClick={(e) => onApplySettings(currentColor)}>
                            Применить
                        </span>
                        <span className={styles.setting_popup_btn + ' primary_60 body_1_long'} onClick={onClose}>
                            Закрыть
                        </span>
                    </div>


                </div>

            </ConfigProvider>
        </>
    )
}
