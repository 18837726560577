/* eslint-disable react/require-default-props */
import classNames from 'classnames';
import React, {FC, MutableRefObject, useEffect, useRef,} from 'react';
import {i18n} from '../../utils';
import styles from './Form.module.scss';

import {Button, ConfigProvider, Flex} from 'antd';

type FormProps = {
    title?: string;
    action?: string;
    btn_title?: string;
    className?: string;
    loading?: boolean;
    children?: React.ReactNode;
    submitHandler: React.FormEventHandler;
};

export const Form: FC<FormProps> = ({
                                        title = 'Form',
                                        action,
                                        btn_title = i18n('Войти'),
                                        children,
                                        submitHandler,
                                        className = '',
                                        loading = false
                                    }) => {
    const submitBtnRef = useRef() as MutableRefObject<HTMLButtonElement>;
    useEffect(() => {
        const listener = (event: { code: string; preventDefault: () => void }) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
                submitBtnRef.current.click();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);


    return (
        <form
            action={action}
            className={classNames(styles.form, {[className]: className})}
            onSubmit={submitHandler}>
            <div className={styles.frame}>
                <div className={styles.title}>{i18n(title)}</div>
                <div
                    className={styles.input_wrapper}
                    id="input-wrapper"
                    data-name="input-wrapper">
                    {children}
                </div>

                <div className={styles.btn_cont}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    colorPrimary: '#F75623',
                                    colorPrimaryHover: '#F75623',
                                    borderRadius:4
                                }
                            },
                        }}>
                        <Flex vertical gap="small" className={styles.flex_button}>

                            <Button type="primary"
                                    ref={submitBtnRef}
                                    loading={loading}
                                    onClick={submitHandler}>
                                <span style={{color: 'white'}} className={'button_1'}>
                                      {!loading ? i18n(btn_title) : <></>}
                                </span>
                            </Button>
                        </Flex>
                    </ConfigProvider>
                </div>
            </div>
        </form>
    );
};
