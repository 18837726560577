import {FC} from 'react';
import {MRuleTypeSchema} from '../../types/MaskingRule/MRuleTypeSchema';
import {Tag} from 'antd';

export type MaskingRuleSchemaParamProps = {

    schema?: MRuleTypeSchema;

};


export const MaskingRuleSchemaParam: FC<MaskingRuleSchemaParamProps> = ({schema}) => {


    return (
        <>
        <span hidden={!schema?.masking_rule_params?.dictionary_id}>
            <Tag>Словарь: {schema?.mRuleSchemaDictName}</Tag>
        </span>

            <span hidden={!schema?.masking_rule_params?.keep_mapping}>
            <Tag> Keep user-token-value mappings</Tag>
        </span>

            <span hidden={!schema?.masking_rule_params?.keep_original_length}>
            <Tag>Keep original string length</Tag>
        </span>
        </>
    );
}
