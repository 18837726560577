import React from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {UsersAll} from './UsersAll';

export function UsersNew() {

    const {pathname} = useLocation();

    return (
        <>
            {pathname === '/control/users' ? (
                <> <UsersAll/> </>
            ) : (
                <>
                    <Outlet/>
                </>
            )}
        </>

    );
}
