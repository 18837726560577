import styles from "./Detokenization.module.scss";
import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {DetokenizeForm} from '../../types/Forms/DetokenizeForm';
import {detokenize} from '../../services/pages/tokenize';
import {JSONSchema7} from 'json-schema';
import {Card} from 'antd';
import {CodeEditor} from '../../components/CodeEditor/CodeEditor';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {TokenizeInfo} from '../../components/TokenizeInfo/TokenizeInfo';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';

export function Detokenization() {

    const [submitting, setSubmitting] = useState(false);
    const [detokenizeResult, setDetokenizeResult] = React.useState([]);
    const [isHidden, setIsHidden] = React.useState(true);
    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});
    const [detokenizeForm, setDetokenizeForm] = useState<DetokenizeForm | undefined>({
        group: "",
        tokens: []
    });

    const [cleanCounter, setCleanCounter] = useState<number>(1);
    const [defaultCodeEditorValue, setDefValue] = useState({
        tokens: [""],
        group: ""
    });

    const doDetokenize = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors(validateValues(detokenizeForm));
        setSubmitting(true);
    }

    const validateValues = (tForm: DetokenizeForm | undefined) => {
        let errors = {
            tokens: "",
            group: ""
        };
        if (!tForm?.tokens) {
            errors.tokens = " tokens обязательное к заполнению поле";
        }
        if (tForm?.group.length == 0) {
            errors.group = "group обязательное к заполнению поле";
        }
        return errors;
    };

    const [errors, setErrors] = useState({
        tokens: "",
        group: ""
    });

    useEffect(() => {
        if (!hasErrors() && submitting) {
            finishSubmit();
        }
        if (hasErrors()) {
            setAntAlertProps({severity: 'error', content: 'Ошибка детокенизации', show: true});
        }
    }, [errors]);

    const hasErrors = () => {
        let res = false;
        if (errors.tokens) res = true;
        if (errors.group) res = true;
        return res;
    }

    const finishSubmit = () => {
        detokenize(detokenizeForm).then((res) => {
            if (res.errors != undefined && res.errors.length > 0) {
                setAntAlertProps({severity: 'error', content: 'Ошибка детокенизации', show: true})
                setIsHidden(true);
            } else {
                setAntAlertProps({severity: 'success', content: 'Запись успешно детокенезирована', show: true})
                setIsHidden(false);
                setDetokenizeResult(res);
            }
        }).catch((error) => {
            setAntAlertProps({severity: 'error', content: 'Ошибка детокенизации', show: true})
            setIsHidden(true);
        });
    }

    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }

    const handleCodeEditorOnChange = (val: string) => {
        try {
            let form = JSON.parse(val);
            setDetokenizeForm(form)
        } catch (e) {
            //to do not valid form
            console.log(e);
        }
    }

    const clearValues = () => {
        let counter = cleanCounter + 1;
        setCleanCounter(counter);
        if (cleanCounter % 2 == 0) {
            setDefValue({
                tokens: [""],
                group: ""
            });
        } else {
            setDefValue({
                tokens: [" "],
                group: " "
            });
        }
    }

    const copyHandler = () => {
        if (detokenizeResult.length != 0) {
            navigator
                .clipboard
                .writeText(JSON.stringify(detokenizeResult, null, 2))
                .then(() =>
                    setAntAlertProps({severity: 'success', content: 'Результат детокенизации скопирован в буфер обмена', show: true})
                );
        }
    }

    const detokenization_shema = {
        type: "object",
        maxProperties: 2,
        minProperties: 2,
        title: "detokenization",
        properties: {
            tokens: {
                type: "array",
                items: {
                    type: "string"
                }
            },
            group: {
                type: "string"
            }
        },
        required: ["tokens", "group"]
    };


    return (
        <Card bordered={false} style={{width: '100%', height: '100%'}} key={'detokenization'}>

            <div className={styles.page}>

                <div className="header_1">
                    Детокенизация
                </div>

                <div className={styles.form}>

                    <div className={styles.form_left}>

                        <div className='card-body'>
                            <form onSubmit={(e) => doDetokenize(e)}>
                                <div>

                                    <div style={{marginBottom: '5px'}}>
                                        <label>Значение для детокенизации
                                            <span className={styles.error}>
                                                *
                                            </span>
                                        </label>
                                    </div>

                                    <CodeEditor defaultValue={defaultCodeEditorValue}
                                                editable={true}
                                                dataSchema={detokenization_shema as JSONSchema7}
                                                onChangeHandler={handleCodeEditorOnChange}/>

                                </div>

                                <div style={{marginTop: '10px'}}>

                                    <ActionButton btnText={'Детокенизировать'}
                                                  htmlType={'submit'}
                                                  type={'primary'}/>

                                    <span style={{marginLeft: '16px'}}>
                                    <ActionButton btnText={'Копировать результат'}
                                                  onClickHandler={copyHandler}
                                                  className={'button_1 primary_80'}
                                                  htmlType={'button'}
                                                  type={'text'}/>

                                    </span>

                                    <span style={{marginLeft: '16px'}}>
                                    <ActionButton btnText={'Очистить значения'}
                                                  onClickHandler={clearValues}
                                                  className={'button_1 primary_80'}
                                                  htmlType={'button'}
                                                  type={'text'}/>
                                    </span>
                                </div>
                            </form>

                        </div>
                    </div>

                    <div className={styles.form_right}>
                        <div hidden={!isHidden}>
                            <div style={{marginBottom: '5px'}}>
                                <label>

                                </label>
                            </div>

                            <TokenizeInfo action={'Детокенизировать'} formFields={'tokens'}/>
                        </div>

                        <div hidden={isHidden}>
                            <div style={{marginBottom: '5px'}}>
                                <label>
                                    Результат
                                </label>
                            </div>
                            <CodeEditor defaultValue={detokenizeResult} editable={false}/>
                        </div>
                    </div>
                </div>

                <div className={styles.notification}>

                    <AntAlert severity={antAlertProps.severity}
                              content={antAlertProps.content}
                              onClose={() => alertClose()}
                              show={antAlertProps.show}/>
                </div>
            </div>
        </Card>
    );
}
