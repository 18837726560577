import {fetchWithRefresh} from '../auth';
import {optionsGet, optionsPost, URL,} from '../requst_templates';
import {handleHttpResponse} from '../../utils';


export const tokenize = async (tForm: any) => fetchWithRefresh(
    `${URL}/v1/tokenize`,
    optionsPost(tForm)
).then(handleHttpResponse);


export const getDetokenize = async (groupId: string, token: string) => fetchWithRefresh(
    `${URL}/v1/detokenize/${encodeURIComponent(groupId)}/${encodeURIComponent(groupId)}`,
    optionsGet(),
).then(handleHttpResponse);


export const detokenize = async (data: any) => fetchWithRefresh(
    `${URL}/v1/detokenize`,
    optionsPost(data)
).then(handleHttpResponse);




