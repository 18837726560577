import React, {FC, useEffect, useState} from 'react';
import {Checkbox, ConfigProvider, Empty, Table} from 'antd';

import styles from './Groups.module.scss';
import {PlusCircleFilled} from '@ant-design/icons';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {TUser} from '../../types/User';

import {uuid} from '../../utils';
import {RowDeleteComponent} from '../../components/RowDeleteComponent/RowDeleteComponent';
import {RowSelectComponent} from '../../components/RowSelectComponent/RowSelectComponent';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {TGroupUserPermission} from '../../types/GroupToken/TGroupUserPermission';
import {TGroup} from '../../types/GroupToken/TGroup';
import {TGroupPermission} from '../../types/GroupToken/TGroupPermission';
import {RuleSelectComponent} from '../../components/RuleSelectComponent/RuleSelectComponent';
import {MRule} from '../../types/MaskingRule/MRule';
import {TGroupUserToRule} from '../../types/GroupToken/TGroupUserToRule';
import {findIndex} from 'lodash';

export type GroupUserPermissionProps = {
    className?: string;
    users?: TUser [];
    hasAdminRole?:boolean;
    maskingRules?: MRule [];
    isValidate: boolean;
    tGroup?: TGroup;
    onValidateFinishHandler: (hasError: boolean) => void;
    onSetPermissionHandler: (tokenize: string[], detokenize: string[], search: string[], detokenizeWithRules: TGroupUserToRule[] ) => void;
    userPermission?: TGroupUserPermission[];


};

export const GroupUserPermission: FC<GroupUserPermissionProps> = ({
                                                                      className,
                                                                      users,
                                                                      hasAdminRole,
                                                                      maskingRules,
                                                                      isValidate,
                                                                      tGroup,
                                                                      onSetPermissionHandler,
                                                                      onValidateFinishHandler,
                                                                      userPermission

                                                                  }) => {

    const [isAddUserHidden, setIsAddUserHidden] = useState<boolean>(true);

    const [data, setData] = useState<TGroupUserPermission[]>([]);

    const [hoverRowId, setHoverRowId] = useState<string>('');

    const [hasPermissionError, setPermissionError] = useState(false);


    const [editingRecord, setEditingRecord] = useState(null);

    const [tokenizePermittedUsers, setTokenizePermittedUsers] = React.useState<string[]>([]);
    const [detokenizePermittedUsers, setDetokenizePermittedUsers] = React.useState<string[]>([]);
    const [detokenizePermittedUsersWithRule, setDetokenizePermittedUsersWithRule] = React.useState<TGroupUserToRule[]>([]);
    const [searchPermittedUser, setSearchPermittedUser] = React.useState<string[]>([]);



    const [dataPermission, setDataPermission] = useState<TGroupUserPermission[]>([]);

    let locale = {
        emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                   description={'Нет данных'}/>
        )
    };

    useEffect(() => {

        if (isValidate) {
            setPermissionError(hasError);
            tokenizePermittedUsers.length=0;
            detokenizePermittedUsers.length=0;
            searchPermittedUser.length=0;
            detokenizePermittedUsersWithRule.length=0;
            convertValues();
            onSetPermissionHandler(tokenizePermittedUsers, detokenizePermittedUsers, searchPermittedUser, detokenizePermittedUsersWithRule);
            onValidateFinishHandler(hasError());
        }
    }, [isValidate]);

    useEffect(() => {
        if (tGroup?.permissions) {
            convertPermission(tGroup['permissions']);
            setData((prev) => [...prev, ...dataPermission]);
            setIsAddUserHidden(false);
        }

    }, [])

    const hasError = () => {
        let hasError = false;

        console.log(data);

        if (!data) return hasError;

        data.forEach(record => {
            hasError = hasErrorForRow(record);
        });

        return hasError;
    }

    const hasErrorForRow = (record: TGroupUserPermission): boolean => {
        let hasError = false;
        if (!record.userId) {
            hasError = true;
        }
        if (record.userId && (!record.tokenize && !record.detokenize && !record.search)) {
            hasError = true;
        }
        return hasError;
    }

    const convertValues = () => {
        let tokenize = tokenizePermittedUsers;
        let detokenize = detokenizePermittedUsers;
        let detokinizeWithRules = detokenizePermittedUsersWithRule;
        let search = searchPermittedUser;

        data.forEach(record => {

            if (record.tokenize) {
                pushIfNotContains(tokenize, record.userId);
            }
            if (record.detokenize) {
                pushIfNotContains(detokenize, record.userId);
                if (record.maskingRuleId) {
                    const newUserToRule = {
                        user_id: record.userId,
                        masking_rule_id: record.maskingRuleId
                    }
                    let index = findIndex(detokinizeWithRules, newUserToRule);
                         if (index == -1) {
                             detokinizeWithRules.push(newUserToRule);
                         }
                }
            }

            if (record.search) {
                pushIfNotContains(search, record.userId);

            }


        });

        setTokenizePermittedUsers([...tokenize]);
        setDetokenizePermittedUsers([...detokenize]);
        setDetokenizePermittedUsersWithRule([...detokinizeWithRules]);
        setSearchPermittedUser([...search]);

    }

    const convertPermission = (permissions: TGroupPermission[] | undefined) => {

        let dataP = dataPermission;

        permissions?.forEach((perm) => {

            const tGroupPermission = dataP.find((val) => val.userId === perm.object_id)
            if (!tGroupPermission) {
                const newPermission = {
                    rowId: uuid(),
                    userId: perm.object_id,
                    tokenize: false,
                    detokenize: false,
                    search: false,
                    maskingRuleId: perm.masking_rule_id||''
                }

                setTGroupUserPermission(newPermission, perm.permission);
                dataP.push(newPermission);
            } else {
                if(perm.masking_rule_id){
                    Object.assign(tGroupPermission, {maskingRuleId: perm.masking_rule_id});
                }
                setTGroupUserPermission(tGroupPermission, perm.permission);
            }
        });

        setDataPermission((prev) => [...prev, ...dataP]);
    }

    const setTGroupUserPermission = (tGroupUserPermission: TGroupUserPermission, permission: string) => {
        switch (permission) {
            case 'tokengroup_tokenize':
                console.log('SWITCH_tokenize');
                Object.assign(tGroupUserPermission, {tokenize: true});
                break;

            case 'tokengroup_detokenize':
                console.log('SWITCH_detokenize');
                Object.assign(tGroupUserPermission, {detokenize: true});
                break;

            case 'tokengroup_search':
                console.log('SWITCH_search');
                Object.assign(tGroupUserPermission, {search: true});
                break;
        }
    }

    const pushIfNotContains = (data: string[], val: string) => {
        let index = data.indexOf(val);

        if (index == -1) {
            data.push(val);
        }
    }

    const addGroupUserPermission = () => {
        setIsAddUserHidden(false);


        const newGroupUserPermission = {

            rowId: uuid(),
            userId: '',
            tokenize: false,
            detokenize: false,
            search: false,
            maskingRuleId: ''
        }

        setData((pre) => {
            return [...pre, newGroupUserPermission]
        });
    }

    const deleteHandler = (record: any) => {
        let removed = data.filter(user => user.rowId != record.rowId);
        setData([...removed]);
    }


    const onCheckboxChange = (event: CheckboxChangeEvent, record: any) => {
        setEditingRecord({...record});

        let targetName = event.target.name;

        switch (targetName) {
            case 'tokenize':
                record['tokenize'] = event.target.checked;
                break;
            case 'detokenize':
                record['detokenize'] = event.target.checked;
                break;
            case 'search':
                record['search'] = event.target.checked;
                break;
        }
    }


    const columns = [
        {
            key: '0',
            title: 'userId',
            dataIndex: 'userId',
            hidden: true
        },

        {
            key: '1',
            title: 'rowId',
            dataIndex: 'rowId',
            hidden: true
        }
        , {
            key: '2',
            title: 'Пользователь',
            render: (record: TGroupUserPermission) => {
                return <RowSelectComponent users={users}
                                           hasAdminRole={hasAdminRole}
                                           record={record}
                                           val={record.userId}/>
            }
        }, {
            key: '3',
            title: 'Токенизация',
            render: (record: any) => <Checkbox name='tokenize'
                                               disabled={!hasAdminRole}
                                               checked={record.tokenize}
                                               onChange={(e) => onCheckboxChange(e, record)}/>
        },
        {
            key: '4',
            title: 'Детокенизация',
            render: (record: any) => <Checkbox name='detokenize'
                                               disabled={!hasAdminRole}
                                               checked={record.detokenize}
                                               onChange={(e) => onCheckboxChange(e, record)}/>
        }, {
            key: '5',
            title: 'Поиск',
            render: (record: any) => <Checkbox name='search'
                                               disabled={!hasAdminRole}
                                               checked={record.search}
                                               onChange={(e) => onCheckboxChange(e, record)}/>
        },
        {
            key: '6',
            title: 'Правило маскирования',
            render: (record: TGroupUserPermission) => {
                return <>{record.detokenize ?
                    <RuleSelectComponent
                    record={record}
                    hasAdminRole={hasAdminRole}
                    val={record.maskingRuleId}
                    maskingRules={maskingRules}/> :
                    '—'}
                </>
            }

        },
        {
            key: '7',
            width: 100,
            render: (record: any) => {
                return <>{hasAdminRole ? <RowDeleteComponent hidden={(hoverRowId != record.rowId)}
                                                             title={'Удалить права пользователя'}
                                                             onClickHandler={() => deleteHandler(record)}/> : <></>}
                </>
            }
        }
    ]


    return (
        <ConfigProvider
            theme={{
                components: {
                    Button: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623',
                        defaultColor: '#F75623',
                        defaultBorderColor: '#F75623',
                    },
                    Select: {
                        colorPrimary: '#F75623',
                        colorBorder: 'none',
                        colorPrimaryHover: '#F75623',
                    },
                    Checkbox: {
                        colorPrimary: '#F75623',
                        colorPrimaryHover: '#F75623'
                    },
                    Table: {
                        headerBg: '#FFFFFF'
                    }
                },
            }}>


            <div className={'body_1_long'} hidden={!isAddUserHidden}>
                <PlusCircleFilled className={styles.user_item_icon}/>

                <div style={{width: '434px'}} className={'body_1_long neutral_90'}>
                    Для работы пользователи с токенами необходимо добавить пользователей и настроить права.
                </div>


            </div>

            <div hidden={isAddUserHidden}>


                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onMouseEnter: () => {
                                setHoverRowId(record.rowId);
                            },
                            onMouseLeave: () => {
                                setHoverRowId('');
                            }
                        };
                    }}
                    rowKey={'rowId'}
                    rowClassName={(record ) => {
                        return hasErrorForRow(record) ? styles.row_error : ''
                    }}
                    locale={locale}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    size="small"/>


            </div>

            <div style={{marginTop: '25px'}}>
                <ActionButton type={'default'}
                              htmlType={'button'}
                              hidden={!hasAdminRole}
                              className={'button_1'}
                              onClickHandler={addGroupUserPermission}
                              btnText={'Добавить пользователя'}/>
            </div>

        </ConfigProvider>
    )


}
