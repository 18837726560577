import styles from './Monitoring.module.scss'
import {Card, ConfigProvider} from 'antd';
import React, {FC} from 'react';
import {InfoCircleFilled} from '@ant-design/icons'


export type MonitoringServerParamProps = {

    title?: string;
    className?: string;
    value?: number;
    valueDesc?: string;

};

export const MonitoringServerParam: FC<MonitoringServerParamProps> = ({
                                                                          title,
                                                                          className,
                                                                          value,
                                                                          valueDesc
                                                                      }) => {
    return (
        <>
            <ConfigProvider
                theme={{
                    components: {

                        Input: {
                            colorPrimary: '#F75623',
                            activeShadow: '0 0 0 1px #F75623',
                            algorithm: true,
                        },
                        Table: {
                            headerBg: '#FFFFFF'
                        },
                        Card: {
                            borderRadius: 6
                        }
                    },
                }}>
                <Card bordered={false}
                      className={className}
                      key={title}
                      style={{width: '33%', height: '106px', marginRight: '10px'}}>
                    <div className={'header_2'} style={{position: 'absolute', top: '10px', left: '10px', width: '100%'}}>
                        <div>
                            {title}
                            <span style={{float: 'right', marginRight: '20px'}}>
                            <InfoCircleFilled className={styles.ram_icon}/>
                        </span>
                        </div>
                    </div>

                    <div style={{position: 'absolute', bottom: '10px', left: '10px'}}>
                        <span className={'monitoring_value'}>

                            {Math.floor((value || 0) * 100) / 100}
                        </span>
                        <span className={'header_1'}>
                            {valueDesc}
                        </span>
                    </div>
                </Card>
            </ConfigProvider>
        </>
    )
}
