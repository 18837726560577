import styles from './Groups.module.scss';
import * as React from "react";
import {useEffect, useState} from "react";

import {createTokenGroup, getTokenGroupWithPermissions, updateTokenGroup} from '../../services/pages/token_group';
import {getCurrentUser, i18n} from "../../utils";
import {TGroup} from '../../types/GroupToken/TGroup';
import {Template} from '../../types/Template';
import {getTemplates} from '../../services/pages/templates';
import {getUsersByFilter} from '../../services/pages/users';
import {UsersResponse} from '../../types/UsersResponse';
import {ActionButton} from '../../components/ActionButton/ActoinButton';
import {Card, Collapse, CollapseProps, ConfigProvider} from 'antd';
import {CommonGroupInfo} from './CommonGroupInfo';
import {CollapseLabel} from '../../components/CollapseLabel/CollapseLabel';
import {GroupUserPermission} from './GroupUserPermission';
import {AntAlert, AntAlertProps} from '../../components/AntAlert/AntAlert';
import {useParams,} from 'react-router';
import {MRuleResponse} from '../../types/MaskingRule/MRuleResponse';
import {getMaskingRules} from '../../services/pages/masking_rule';
import {TGroupUserToRule} from '../../types/GroupToken/TGroupUserToRule';
import {RequestFilter} from '../../types/RequestFilter/RequestFilter';
import {getDictionariesByFilter} from '../../services/pages/dictionary';
import {DictionaryItem} from '../../types/DictionaryItem';
import {ClassToDictionary} from '../../types/ClassToDictionary';
import {assign, find, set, cloneDeep } from 'lodash';
import {TGroupDefaultTemplateParams} from '../../types/GroupToken/TGroupDefaultTemplateParams';
import {getDataClass} from '../../services/pages/data_class';
import {RequestFilterBuilder} from '../../types/RequestFilter/RequestFilterBuilder';


export function Group() {

    const [templates, setTemplates] = useState<Template[] | undefined>(undefined);
    const [users, setUsersData] = useState<UsersResponse['items'] | undefined>(undefined);
    const [rules, setRulesData] = useState<MRuleResponse['items'] | undefined>(undefined);
    const [isValidate, setIsValidate] = useState<boolean>(false);
    const [antAlertProps, setAntAlertProps] = useState<AntAlertProps>({severity: '', content: '', show: false});
    const [hasAdminRole, setHasAdminRole] = useState<boolean>(false);
    const [dictionaries, setDictionariesData] = useState<DictionaryItem[]>([]);
    const [classData, setClassData] = useState<ClassToDictionary[]>([]);
    const [defaultTemplateParams] = useState<TGroupDefaultTemplateParams>({});

    const [classes, setClasses] = useState<ClassToDictionary[]>([]);
    const [isClassesLoad, setClassesLoad] = useState<boolean>(false);
    const [isGroupLoad, setGroupLoad] = useState<boolean>(false);

    const [validateResults, setValidateResults] = useState({
        hasCommonError: true,
        hasPermissionError: false,
    });

    const {id} = useParams();

    useEffect(() => {
        getTemplatesData();
        getAllUsers();
        getGroupData();
        getMaskingRulesData();
        getAllDictionaries();
        getAllDataClass();

        let cUser = getCurrentUser();
        setHasAdminRole(cUser?.roles.indexOf('admin') !== -1);
    }, []);

    useEffect(()=>{
        initClassData(tGroup?.default_template_params);
    },[isGroupLoad, isClassesLoad]);

    const getTemplatesData = () => {
        getTemplates()
            .then((response) => {
                    setTemplates(response);
                }
            ).catch((err) => console.log(err))
    }

    const getAllUsers = () => {
        let filter = new RequestFilter();
        filter.limit = 1000;
        filter.offset = 0;
        filter.sortBy = 'username';
        filter.sortOrder = 'asc';

        getUsersByFilter(filter).then((resp) => {
            setUsersData(resp['items']);
        });
    }

    const getAllDictionaries = () => {
        let filter = new RequestFilterBuilder()
            .setLimit(1000)
            .setOffset(0)
            .setSortBy('name')
            .setSortOrder('asc')
            .build();

        getDictionariesByFilter(filter).then((resp)=> {
            setDictionariesData(resp['items']);
        });
    }

    const getGroupData = () => {
        if (id) {
            getTokenGroupWithPermissions(id || '').then((response) => {
                    setTGroup(response);
                    setGroupLoad(true);
                }
            ).catch((err) => console.log(err));
        }
    }

    const getAllDataClass = () => {
        getDataClass().then((resp)=> {

            let dataClasses = resp as ClassToDictionary[];

            dataClasses = dataClasses.filter((item)=> item.action_type ==='DICTIONARY')
            setClasses(dataClasses);
            setClassData(dataClasses);
            setClassesLoad(true);

        });
    }

    const getMaskingRulesData = () => {
        getMaskingRules().then((resp) => {
            setRulesData([...resp['items']]);
        });
    }

    const [tGroup, setTGroup] = useState<TGroup>({
        id: "",
        name: "",
        description: "",
        default_template_id: "",
        default_template_params: defaultTemplateParams
    });

    const handleChange = (e: any) => {
        const value = e.target.value;
        setTGroup({
            ...tGroup, [e.target.name]: value,
        });
    }

    const handleTemplateChange = (val: string) => {
        setTGroup({
            ...tGroup, ['default_template_id']: val,
        });
        initClassData(tGroup.default_template_params);
    }

    useEffect(() => {
        if (!validateResults.hasCommonError && !validateResults.hasPermissionError) {
            console.log("FINISH_SUBMIT")
            finishSubmit();
        }
    }, [validateResults]);

    const finishSubmit = () => {
        saveGroup(tGroup);
    }

    const saveGroup = (tGroup: TGroup) => {
        setDefaultTemplateParam();
        if (id) {
            delete tGroup.id;
            delete tGroup.store_fields;
            updateTokenGroup(id || '', tGroup).then((res) => {
                handleResponse(res, 'update');
                setIsValidate(false);
            }).catch((error) => {
                setAntAlertProps({
                    severity: 'error',
                    content: i18n('update_group_error') + error,
                    show: true
                });
            });
        } else {
            createTokenGroup(tGroup)
                .then((res) => {
                    handleResponse(res, 'create');
                    setIsValidate(false);
                }).catch((error) => {
                setAntAlertProps({
                    severity: 'error',
                    content: i18n('create_group_error') + error,
                    show: true
                })
            });
        }
    }
    
    const setDefaultTemplateParam = () => {
        if (tGroup.default_template_id ==='dictionary') {
            classData.forEach((cls)=> {
                Object.assign(defaultTemplateParams, {[cls.code]:cls.dictionary_id});
            });
            set(tGroup, 'default_template_params',defaultTemplateParams);
        } else{
            set(tGroup, 'default_template_params', {});
        }
    }

    const initClassData = (defaultTemplateParams: TGroupDefaultTemplateParams) => {
        let classesCopy = cloneDeep(classes);
        Object.entries(defaultTemplateParams).forEach(([key, value]) => {
            let val = defaultTemplateParams[key as keyof typeof defaultTemplateParams];
            let classToUpdate = find(classesCopy, {code: key});
            if (classToUpdate) {
                assign(classToUpdate, {dictionary_id: val})
            }
        });
        setClassData(classesCopy);
    }

    const handleResponse = (res: any, action: string) => {

        if (res.errors != undefined && res.errors.length > 0) {
            setAntAlertProps({
                severity: 'error',
                content: i18n(action + '_group_error') + tGroup.name + ' ' + res.errors[0].message,
                show: true
            });
        } else {
            setAntAlertProps({
                severity: 'success',
                content: i18n(action + '_group_success') + tGroup.name + ' ',
                show: true
            });
        }
    }

    const alertClose = () => {
        setAntAlertProps({severity: 'info', content: '', show: false})
    }


    const setPermissionHandler = (tokenize: string[], detokenize: string[], search: string[], detokenizeWithRules: TGroupUserToRule[] ) => {
        Object.assign(tGroup, {tokenize_permitted_users: tokenize});
        Object.assign(tGroup, {detokenize_permitted_users: detokenize});
        Object.assign(tGroup, {search_permitted_users: search});
        Object.assign(tGroup, {detokenize_permitted_users_with_masking_rules: detokenizeWithRules});
    }

    const createGroup = () => {
        setIsValidate(true);
    }

    const onCommonValidateHandler = (hasError: boolean) => {
        setIsValidate(false);
        setValidateResults((pre) => {
            return {...pre, hasCommonError: hasError}
        });
    }

    const onPermissionValidateHandler = (hasError: boolean) => {
        setIsValidate(false);
        setValidateResults((pre) => {
            return {...pre, hasPermissionError: hasError}
        });
    }

    const items: CollapseProps['items'] = [
        {
            key: 'common',
            label: <CollapseLabel text={'Общая информация'}/>,
            children: <CommonGroupInfo templates={templates}
                                       dictionaries={dictionaries}
                                       onValidateFinishHandler={onCommonValidateHandler}
                                       isValidate={isValidate}
                                       tGroup={tGroup}
                                       classData={classData}
                                       hasAdminRole={hasAdminRole}
                                       onInputChange={handleChange}
                                       onSelectChange={handleTemplateChange}/>,
        },
        {
            key: 'permission',
            label: <CollapseLabel text={'Права пользователей'}/>,
            children: <GroupUserPermission users={users}
                                           hasAdminRole={hasAdminRole}
                                           maskingRules={rules}
                                           onValidateFinishHandler={onPermissionValidateHandler}
                                           isValidate={isValidate}
                                           tGroup={tGroup}
                                           onSetPermissionHandler={setPermissionHandler}/>,
        }
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Collapse: {
                        borderRadiusLG: 0,

                    }
                },
            }}>
            <Card bordered={false} style={{width: '100%', height: '100%', overflowY:'auto'}} key={'group'}>
                <div id="group_page" className={styles.page}>

                    <div className="header_1">
                        { id ? "Редактировать группу токенизации" : "Добавить группу токенизации" }
                        <ActionButton btnText={'Сохранить'}
                                      htmlType={'submit'}
                                      hidden={!hasAdminRole}
                                      type={'primary'}
                                      onClickHandler={createGroup}
                                      className={styles.float_right}/>
                    </div>

                    <Collapse items={items}
                              defaultActiveKey={['common']}
                              bordered={false}
                              ghost={true}/>

                    <div className={styles.form}>

                    </div>

                    <div className={styles.notification}>

                        <AntAlert severity={antAlertProps.severity}
                                  content={antAlertProps.content}
                                  onClose={() => alertClose()}
                                  show={antAlertProps.show}/>
                    </div>
                </div>
            </Card>
        </ConfigProvider>
    );
}
