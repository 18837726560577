import React, {FC} from 'react';
import {json, jsonLanguage, jsonParseLinter} from '@codemirror/lang-json';
import {linter, lintGutter} from '@codemirror/lint';
import {handleRefresh, jsonCompletion, jsonSchemaHover, jsonSchemaLinter, stateExtensions} from 'codemirror-json-schema';
import {EditorView, hoverTooltip} from '@codemirror/view';
import {JSONSchema7} from 'json-schema';
import CodeMirror from '@uiw/react-codemirror';
import {createTheme} from '@uiw/codemirror-themes';


export type CodeEditorProps = {
    defaultValue?: object;
    dataSchema?: JSONSchema7;
    onChangeHandler?: (val: string) => void;
    editable: boolean
};

export const CodeEditor: FC<CodeEditorProps> = ({
                                                    defaultValue,
                                                    dataSchema,
                                                    onChangeHandler,
                                                    editable
                                                }) => {

    const codeEditorViewExtension = EditorView.theme(
        {
            '& .cm-gutters': {
                backgroundColor: '#9BA0AA',
                border: '1px solid #9BA0AA',
                color: '#FFFFFF',
                borderLeft: '1px solid #9BA0AA',
                borderTop: '1px solid #9BA0AA',
                borderBottom: '1px solid #9BA0AA',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
            },
            '& .cm-content': {

                borderRight: '1px solid #9BA0AA',
                borderTop: '1px solid #9BA0AA',
                borderBottom: '1px solid #9BA0AA',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',

                fontFamily: 'Mulish-Regular',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px'
            },
            '& .cm-diagnostic': {
                backgroundColor: '#35383F',
                color: '#FFFFFF',
                border: '2px solid #35383F',
                borderRadius: '4px'
            },
            '& .cm-diagnostic-error': {
                borderLeft: 'none'
            },
            '& .cm-lint-marker-error': {
                content: 'url(\'data:image/svg+xml,' +
                    '<svg width="12" ' +
                    'height="12" ' +
                    'viewBox="0 0 12 12" ' +
                    'fill="none" ' +
                    'xmlns="http://www.w3.org/2000/svg">' +
                    '<circle cx="6" cy="6" r="6" fill="%23D92020"/>' +
                    '</svg>\')'

            }
        },
        {
            dark: false,
        },
    );

    const damaskTheme = createTheme({
        theme: 'light',
        settings: {
            background: '#ffffff',
            foreground: '#23262D',
            gutterBackground: '#9BA0AA',
            gutterForeground: '#FFFFFF',
            caret: '#AEAFAD',
            selection: '#D6D6D6',
            selectionMatch: '#D6D6D6',
            gutterBorder: '#dddddd',
            gutterActiveForeground: '',
            lineHighlight: 'transparent',
        },
        styles: [],
    });


    return (
        <CodeMirror id={'editor'}
            value={JSON.stringify(defaultValue, null, 2)}
            height="232px"
            theme={damaskTheme}
            editable={editable}
            ref={(ref)=> this}
            extensions={[json(),
                linter(jsonParseLinter(), {
                    delay: 300
                }),
                linter(jsonSchemaLinter(), {
                    needsRefresh: handleRefresh,
                }),
                jsonLanguage.data.of({
                    autocomplete: jsonCompletion(),
                }),
                hoverTooltip(jsonSchemaHover()),
                stateExtensions(dataSchema as JSONSchema7),
                lintGutter(),
                codeEditorViewExtension]}
            onChange={(value, viewUpdate) => {
                if (onChangeHandler) {
                    onChangeHandler(value);
                }
            }}
        />
    )
}
