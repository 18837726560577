import {ConfigProvider} from 'antd';
import React, {FC} from 'react';
import {MonitoringServerParam} from './MonitoringServerParam';
import {ArrowRightOutlined} from '@ant-design/icons';
import {MonitoringParam} from '../../types/Manitoring/MonitoringParam';
import {doNavigate} from '../../utils';
import {useNavigate} from 'react-router-dom';
import {MonitoringNode} from '../../types/Manitoring/MonitoringNode';

export type MonitoringServerProps = {

    title?: string;
    className?: string;
    params?: MonitoringParam;
    id: string;
    name?: string;
    monitoringNode: MonitoringNode;

};

export const MonitoringServer: FC<MonitoringServerProps> = ({
                                                                id,
                                                                name,
                                                                monitoringNode,
                                                                title,
                                                                params
                                                            }) => {
    const navigate = useNavigate();

    const onDetailClick = () => {
        doNavigate('/monitoring/server/' + `${id}/` + `${name}`, navigate);
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {

                        Input: {
                            colorPrimary: '#F75623',
                            activeShadow: '0 0 0 1px #F75623',
                            algorithm: true,
                        },
                        Table: {
                            headerBg: '#FFFFFF'
                        }
                    },
                }}>
                <div className={'header_2'}
                     style={{marginTop: '20px', marginBottom: '10px'}}>
                    <div style={{marginBottom: '20px'}}>
                        {title}
                        <span style={{float: 'right', cursor: 'pointer'}}
                              className={'button_1 primary_60'}
                              onClick={onDetailClick}>
                            Детализация <ArrowRightOutlined/>
                        </span>
                    </div>

                </div>

                <div style={{display: 'flex'}}>
                    <MonitoringServerParam title={'RAM'}
                                           value={monitoringNode.current_ram_load}
                                           valueDesc={params?.ram_value_desc}
                                           className={params?.ram_class_name}/>
                    <MonitoringServerParam title={'Загрузка CPU'}
                                           value={monitoringNode.current_cpu_load}
                                           valueDesc={params?.cpu_value_desc}
                                           className={params?.cpu_class_name}/>
                    <MonitoringServerParam title={'Использовано памяти'}
                                           value={monitoringNode.storage_free}
                                           valueDesc={params?.ram_used_value_desc}
                                           className={params?.ram_used_class_name}/>
                </div>

            </ConfigProvider>
        </>
    )
}
